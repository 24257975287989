import { createSlice } from "@reduxjs/toolkit";
import axiosAuth from "../auth/jwtMiddleware";

const url = process.env.REACT_APP_PUBLIC_URL;

export const notificationSlice = createSlice({
    name:"nofication",
    initialState:{
        notificationsData:[],
        errorMessage:'',
        isNotificationsLoading:false,
        chat_request_id:'',
        ghostPoup:false,
        premiumPopUp :false,
        icCompletePopUp :false,
        frozenPopUp:false,
        isIphonePopUp:false,
        notificationsCounter:[],
    },
    reducers:{
        startnotificationsloading:(state)=>{
            state.isNotificationsLoading = true;
        },
        initializeNotificationData:(state, action)=>{
            state.isNotificationsLoading =false;
            state.notificationsData = action.payload;
            state.errorMessage =''
        },
        errorNotifications:(state, action) =>{
            state.isNotificationsLoading =false;
            state.errorMessage =action.payload
        },

        startnotificationscountloading :(state, action) =>{

        },
        initializeNotificationsCounter :(state, action) =>{
            state.notificationsCounter = action.payload
        },
        clearNotificationsData :(state) =>{
            state.notificationsData=[];
            state.errorMessage='';
            state.isNotificationsLoading=false;
        },

        updateNotifications :(state, action) =>{
            state.notificationsData = action.payload;
        },
        updateNotificationsCounter :(state , action) => {
            state.notificationsCounter = action.payload
        },
        updateChatRequestId:(state, action) =>{
            state.chat_request_id = action.payload
        },
        updateGhostPopup:(state, action) =>{
            state.ghostPoup = action.payload
        },
        updatePremiumPopup :(state, action) =>{
            state.premiumPopUp = action.payload
        },
        updateIsCompletePopup:(state, action) =>{
            state.icCompletePopUp = action.payload
        },
        updateFrozenPopUp :(state, action) =>{
            state.frozenPopUp = action.payload
        },
        updateIphonePopUp: (state, action) =>{
            state.isIphonePopUp = action.payload
        }
    }
});

export const {
    startnotificationsloading,
    initializeNotificationData,
    errorNotifications,
    clearNotificationsData,
    updateNotifications,
    updateChatRequestId,
    updateGhostPopup,
    updatePremiumPopup,
    updateIsCompletePopup,
    updateFrozenPopUp,
    updateIphonePopUp,
    startnotificationscountloading,
    initializeNotificationsCounter,
    updateNotificationsCounter
} = notificationSlice.actions;

export default notificationSlice.reducer;


export const getNotifications = (datavalue) =>async (dispatch) =>{
    dispatch(startnotificationsloading());
    // console.log(datavalue)

    try{
        await axiosAuth().post(`${url}/users/get-notifications`,datavalue)
                .then((response)=>{
                    if(response?.data?.data){
                        dispatch(initializeNotificationData(response?.data?.data))
                    }

                }).catch(error=>{
                    dispatch(errorNotifications(error))
                })
    }
    catch(e){
        dispatch(errorNotifications(e))
    }
}

// export const getNotificationsUpdates = (datavalue) =>async (dispatch) =>{
//     dispatch(startnotificationsloading());
//     // console.log(datavalue)

//     try{
//         await axiosAuth().post(`${url}/users/get-notifications`,datavalue)
//                 .then((response)=>{
//                     if(response?.data?.data){
//                         // dispatch(initializeNotificationData(response?.data?.data))
//                     }

//                 }).catch(error=>{
//                     dispatch(errorNotifications(error))
//                 })
//     }
//     catch(e){
//         dispatch(errorNotifications(e))
//     }
// }


export const updateNotificationData = (data) => async (dispatch) =>{
    dispatch(initializeNotificationData(data))
}

export const getNotificationsCount = (user) => async (dispatch) =>{
    dispatch(startnotificationscountloading());
    // console.log(datavalue)

    try{
        await axiosAuth().post(`${url}/users/get-notifications-count`,user)
                .then((response)=>{
                    if(response?.data?.data){
                        dispatch(initializeNotificationsCounter(response?.data?.data))
                    }

                }).catch(error=>{
                    dispatch(errorNotifications(error))
                })
    }
    catch(e){
        dispatch(errorNotifications(e))
    }
} 

export const setclearNotifications =() =>async (dispatch) =>{
    dispatch(clearNotificationsData())
}

export const setUpdateNotifications = (data) => async(dispatch) => {
    dispatch(updateNotifications(data))
}

export const setUpdateNotificationsCounter = (data) => async(dispatch) => {
    dispatch(updateNotificationsCounter(data))
}

export const setUpdateChats = (data) => async (dispatch) => {
    dispatch(updateChatRequestId(data))
}

export const setGhostPopUp = (data) => async (dispatch) =>{
    dispatch(updateGhostPopup(data))
}

export const setPremiumPopUp = (data) => async (dispatch) =>{
    dispatch(updatePremiumPopup(data))
}

export const setCompletePopUp = (data) => async (dispatch) =>{
    dispatch(updateIsCompletePopup(data))
}


export const setfrozenPopUp =(data) => async (dispatch)=>{
    dispatch(updateFrozenPopUp(data))
}


export const setIphonePopUp =(data) => async (dispatch)=>{
    dispatch(updateIphonePopUp(data))
}