import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toggleNotification } from "../../app/actions/Global";
import sample5 from "../../assets/images/Main/sample-user-feed-5.jpg";
import sample3 from "../../assets/images/Main/sample-user-feed-3.jpg";
import sample6 from "../../assets/images/Main/sample-user-feed-6.jpg";
import sample2 from "../../assets/images/Main/sample-user-feed-2.jpg";
import sample4 from "../../assets/images/Main/sample-user-feed-4.jpg";
import {
  getNotifications,
  updateNotificationData,
} from "../../app/actions/Notifications";
import { useTranslation } from "react-i18next";
// import AxiosWithAuth from "../auth/jwtMiddleware";
import axiosWithAuth from "../../app/auth/jwtMiddleware";

const url = process.env.REACT_APP_PUBLIC_URL;

export default function Notifications() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { notificationsData,isNotificationsLoading } = useSelector((state) => state.notifications);
  const { profileData } = useSelector((state) => state.profile);
  const [updateNotificationsData, setUpdateNotificationsData] = useState({
    notifications: [],
  });
  const [allLoaded, setAllLoaded] = useState(false);

  const { notificationToggle } = useSelector((state) => state.global);

  const [loading, setLoading] = useState(false);
  const page = useRef(0);
  const notificationsRef = useRef(null);

  const notificationsDisplay = useRef();
  // console.log(notificationsData?.notifications);

  function action(dataValue) {
    var message = "";
    var link = {};
    // eslint-disable-next-line default-case
    switch (dataValue.type) {
      case "like":
        message = t("global-liked-notification", "liked you");
        link = "/user/" + dataValue?.data?.slug;
        break;
      case "view":
        message = t("viewed-notification");
        link = "/user/" + dataValue.data.slug;
        break;
      case "nudge":
        message = t("nudge-notification");
        link = "/messages?u=" + dataValue.data.chat_id;
        break;
      case "message-request":
        message = t("message-notification");
        link = "/messages?u=" + dataValue.data.chat_id;
        break;
      case "call-request":
        message = t("call-notificaiton");
        link = "/messages?u=" + dataValue.data.chat_id;
        break;
    }
    var data = {
      message: message,
      link: link,
    };

    return data;
  }

  useEffect(() => {
    if (notificationToggle) {
      document.querySelector(".overlay").style.display = "block";
      document.getElementById("topbar").classList.add("topbaractive");
      document.getElementById("sidebar").classList.add("toplogoactive");
      document.getElementById("root").classList.add("noscroll");
    } else {
      setUpdateNotificationsData(null);
      page.current = 0;
      document.querySelector(".overlay").style.display = "none";
      document.getElementById("topbar").classList.remove("topbaractive");
      document.getElementById("sidebar").classList.remove("toplogoactive");
      document.getElementById("root").classList.remove("noscroll");
      // topbaractive
    }

    // console.log(notificationToggle)
  }, [notificationToggle]);

  function GetNotifications(page) {
    var dataread = {
      user: profileData?.main?.id,
      read: true,
      limit: 30,
      page: page,
    };
    // console.log('notificattion')

    dispatch(getNotifications(dataread))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }

  useEffect(() => {
    
    if (!updateNotificationsData?.notifications?.length) {
      setUpdateNotificationsData((prevData) => ({
        ...prevData,
        notifications: notificationsData.notifications,
      }));
      
    }
    notificationsDisplay.current = notificationsData;
  }, [notificationsData]);

  async function getNotificationsUpdates(page) {
    var data = {
      user: profileData?.main?.id,
      read: true,
      limit: 30,
      page: page,
    };
    // function updateGetNotifications (page){
    try {
      await axiosWithAuth()
        .post(`${url}/users/get-notifications`, data)
        .then((response) => {
          if (response?.data?.data) {
            // console.log(response?.data?.data?.notifications);

            if (response?.data?.data?.notifications.length >= 0) {
              if (response?.data?.data?.notifications.length > 0) {
                setUpdateNotificationsData((prevData) => {
                  return {
                    ...prevData,
                    notifications: [
                      ...prevData.notifications,
                      ...response?.data?.data?.notifications,
                    ],
                  };
                });
              } else {
                setAllLoaded(true);
              }
              // console.log(updateNotificationsData)
              // notificationsData?.notifications
            }

            // dispatch(initializeNotificationData(response?.data?.data))
          }
        })
        .catch((error) => {
          // dispatch(errorNotifications(error))
        });
    } catch (e) {
      // dispatch(errorNotifications(e))
    }
  }

  function handleClick(e, data) {
    e.preventDefault();

    GetNotifications(0);

    navigate(data);
    // console.log(data)
  }

  useEffect(() => {
    const notsRef = notificationsRef.current;
    page.current = 0;

    const handleScroll = () => {
      if (notsRef && !loading && !allLoaded) {
        const isAtBottom =
          notsRef.scrollTop + notsRef.clientHeight >= notsRef.scrollHeight;

        // console.log(allRecentlyLoaded)
        if (isAtBottom) {
          page.current = page.current + 1;
          getNotificationsUpdates(page.current);
        }
      }
    };

    if (notsRef) {
      // notsRef.removeEventListener("scroll", handleScroll);
      notsRef.addEventListener("scroll", handleScroll);
    }

    return () => {
      notsRef.removeEventListener("scroll", handleScroll);
    };
  }, [loading]);

  // useEffect(() => {
  
  //   console.log(notificationsDisplay.current);
  // }, [updateNotificationsData]);

  return (
    <>
      <div
        id="notification-wrap"
        className={notificationToggle ? "clearfix active" : "clearfix"}
      >
        <div className="heading">{t(["notifications-label", ""])}</div>

        <ul className="feeds" id="notificationFeeds" ref={notificationsRef}>
          {!profileData?.is_premium && (
            <>
              <div id="notificationAd">
                <div className="wrap">
                  <div className="lft">
                    {/* <img src="{{ asset('images/ad/crown-tilted.svg') }}" /> */}
                    <h3>Unlock Premium Features </h3>
                    <a href="/premium">Learn More</a>
                  </div>
                  <div className="rght">
                    <ul className="checklist">
                      <li>Video calling</li>
                      <li>3x more matches</li>
                      <li>Advanced filter options</li>
                      <li>See who's viewed your profile</li>
                    </ul>
                  </div>
                </div>
              </div>
            </>
          )}




        {isNotificationsLoading? (<>
        
        
        </>):(<>
        
          {notificationsDisplay?.current?.notifications &&
          notificationsDisplay?.current?.notifications?.length > 0 ? (
            <>
              {notificationsDisplay?.current?.notifications?.map((data, id) => (
                <li
                  className={`${data?.read ? "feed " : "feed new"} ${
                    data?.type
                  }`}
                  key={id}
                >
                  <div
                    className={
                      data?.data?.is_premium ? "user is_premium_user" : "user is_premium_user"
                    }
                  >
                    <div className="photo">
                      <img src={data?.data?.photo} alt={data?.data?.name} />
                    </div>
                    <div className="details">
                      <div>
                        <span className="name">
                          {" "}
                          {profileData?.is_premium ||
                          data?.type === "message-request" ||
                          data?.type === "nudge"
                            ? data?.data?.name
                            : "Someone"}{" "}
                        </span>
                        {action(data)?.message}
                      </div>
                      <div className="timestamp">{data?.date}</div>
                    </div>
                  </div>

                  <div className="actions">
                    <a
                      onClick={(e) =>
                        handleClick(
                        e,
                        action(data)?.link
                        )
                      }
                      href={` ${
                        action(data)?.link
                      }`}
                      className={`redbutton `}
                    >
                      {`${t("view-button")}`}
                    </a>
                  </div>
                </li>
              ))}
            </>
          ) : (
            <>
              <div className=" d-flex h-100 justify-content-center text-lg-center p-5">
                <p className="nonotifications">
                  {t(["no-notifications-text", ""])}
                </p>
              </div>
            </>
          )}
        
        
        </>)}

        </ul>
      </div>
    </>
  );
}
