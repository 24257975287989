import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  toggleNotification,
  toggleSetting,
  initUserData,
  initTokenData,
  updateSidebarToggle,
  getLiveUsers,
} from "../../app/actions/Global";
import {
  setClearHome } from "../../app/actions/Home";
import {
  getNotifications,
  getNotificationsCount,
} from "../../app/actions/Notifications";
import imagePlacholder from "../../assets/images/Main/user-placeholder.jpg";
import GhostToggle from "../../components/GhostToggle";

import { useTranslation } from "react-i18next";
import "./topbar.scss";

import AxiosWithAuth from "../../app/auth/jwtMiddleware";

import { showAlert } from "../../components/showAlerts";

import { validateGobalCall } from "../../pages/messages/videoFunctions";
import { Link } from "react-router-dom";
import { LogOut, capitalizeWords } from "../../app/utilities/functions";

import PremiumPopup from "../../components/UpgradePopups/PremiumPopup";
import IphonePopup from "../../components/IphonePopup/index";
import VerificationPopup from "../../components/VerificationPopup";
import GhostPopup from "../../components/UpgradePopups/GhostPopup";
import {updateMessageConnectesStatus} from "../../app/actions/Messages"

// muthu 
import ProfileFrozen from "../../components/GlobalPopups/Frozen";
import PaymentSuccessPopup from "../../components/PaymentSuccessPopup/success";
//
import Echo from "laravel-echo";
import io from "socket.io-client";
import axiosWithAuth from "../../app/auth/jwtMiddleware";
import { use } from "i18next";
import { getLanguageFromLocalStorage } from "../../app/utilities/localization";

import {
  emptyUserFeedObject
} from "../../app/actions/Home";



const url = process.env.REACT_APP_PUBLIC_URL;

const LARAVEL_CHANNEL_HOST = process.env.LARAVEL_CHANNEL_HOST;

export default function Topbar({setfilterSlider}) {
  const { t } = useTranslation();

  // const hasIncognito =
  const dispatch = useDispatch();
  const location = useLocation();

  var incomingCallCount = 0;
  var incomingCallTimer = null;

  const { notificationToggle, settingsToggle, sidebarTogggle } = useSelector(
    (state) => state.global
  );
  const { notificationsData, chat_request_id, notificationsCounter } =
    useSelector((state) => state.notifications);
  const { profileData } = useSelector((state) => state.profile);
  const [hasIncognito, setHasIncognito] = useState(false);
  const [calData, setCallData] = useState([]);
  const [settingClicked, setSettingClicked] = useState(false);
  const topNavDropDownRef = useRef(null);
  const language = getLanguageFromLocalStorage() || "en";
  const [liverUsers, setliveUsers] = useState([]);
  const [timer, setTimer] = useState(0);
  const [disablePopups, setDisablePopups] = useState(false);
  const {connectedMessage} = useSelector((state)=>state.messages)



  useEffect(() => {
    const currentLocation = localStorage.getItem('current_page_location');
    // if(location.pathname !== '/' && window.innerWidth <= 767){
 
    // }
    if (currentLocation) {
      localStorage.setItem('prev_page_location', currentLocation);
    }

    localStorage.setItem('current_page_location', location.pathname);

    // if(location.pathname === '/' && currentLocation==='/'){
    //   localStorage.setItem('currentPageDesktop', 1);
    // }
  }, [location]);


  useEffect(() => {
    function handleClickOutside(event) {
      // if(event.target.classList[0]!=='emote-popup' && event.target.classList[0]!=='emoteBt') {
      console.log();
      if (
        topNavDropDownRef.current &&
        !topNavDropDownRef.current.contains(event.target)
      ) {
        if (
          settingClicked &&
          event.target.classList[0] !== "dropdown-nav-item"
        ) {
          dispatch(toggleSetting(false));
          setSettingClicked(false);
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [settingClicked, dispatch]);

  function handleSettingsToggle() {
    setSettingClicked(true);
    dispatch(toggleSetting(!settingsToggle));
  }

  useEffect(() => {
    profileData?.addons?.some((addon) => addon.plan.slug === "incognito")
      ? setHasIncognito(true)
      : setHasIncognito(false);
  }, [profileData]);

  function handleNotificationToggle() {
    if (notificationToggle) {
      getGlobalNotifications(true);
    } else {
      getGlobalNotifications(false);
    }

    dispatch(toggleNotification(!notificationToggle));
  }

  function handleSettingsToggle() {
    setSettingClicked(true);
    dispatch(toggleSetting(!settingsToggle));
  }

  useEffect(() => {
    window.onbeforeunload = (event) => {
      // event.preventDefault();
      // Show prompt based on stat
      // console.log("refresh");
      // console.log(settingsToggle);
      dispatch(toggleNotification(false));
      dispatch(toggleSetting(false));
    };
  }, []);

  // useEffect(() => {
  //   const queryParams = new URLSearchParams(location?.search);
  //   const first_plan = queryParams.get("plan");
  //   if (first_plan) {
  //   setDisablePopups(true);
  //   }
  // }, []);

  const token = localStorage.getItem("authtoken");

  useEffect(() => {
    if (profileData?.online_users > 0) {
      var numb = profileData?.online_users?.toLocaleString();
      setliveUsers(numb);
    }
  }, [profileData]);

  useEffect(() => {
    window.Echo = new Echo({
      broadcaster: "socket.io",
      host: process.env.REACT_APP_LARAVEL_CHANNEL_HOST, // Adjust the host and port as needed
      client: io,
      transports: ["websocket"],
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      // wsHost: 'https://app.mohabbatein.com',
      wsHost: process.env.REACT_APP_LARAVEL_CHANNEL_HOST_NOPORT,
      wsPort: 6001,
    });

    window.Echo.connector.connect();
    window.Echo.channel("notifications-" + profileData?.main?.id).listen(
      ".Notification",
      (data) => {
        if (data?.type === "message-received") {
          if (data?.data?.chat_request_id !== chat_request_id) {
            getGlobalNotifications(false);
          }

        }

        if (
          data?.type === "nudge" ||
          data?.type === "like" ||
          data?.type === "view" ||
          data?.type === "nudge" ||
          data?.type === "message-request" ||
          data?.type === "call-request"
        ) {
          getGlobalNotifications(false);
        }

        // console.log(location.pathname);
        // console.log(data.type);
        if (data.type === "video-chat-request") {
          // console.log("video chat is here");
          if (
            location.pathname !== "/messages" &&
            window.location.pathname !== "/messages" &&
            location.pathname !== "/messages/" &&
            window.location.pathname !== "/messages/"
          ) {
            // console.log("not in messages");
            // console.log(location.pathname)
            // console.log(location)
            // console.log(window.location.pathname)

            //
            validateGobalCall(
              data?.data?.call_id,
              profileData,
              data?.data?.caller_id,
              data?.data?.chat_id
            );

            setCallData(
              <>
                {`${data.data.first_name} ${data.data.last_name} ${t([
                  "is-calling-message",
                  "",
                ])}`}
                <div className="buttons">
                  <a
                    href={`/messages?u=${data.data.chat_id}&cid=${data.data.call_id}&clrd=${data.data.caller_id}`}
                    className="answer"
                  >
                    {t(["accpet-button", "Accept"])}
                  </a>
                  <Link
                    to="#"
                    className="reject"
                    onClick={(e) => rejectCall(e, data, profileData)}
                  >
                    {t(["ignore-button", "Ignore"])}
                  </Link>
                </div>
              </>
            );
            //  document.getElementById("callnotification").innerHTML =call

            // document.getElementById("callnotification").classList.add("active");
          }
        }
      }

      // notificationSound('pop');
    );

    //

    //
    const onlineChannel = window.Echo.join("online");
    // console.log('online')
    // console.log(onlineChannel);
    // console.log(onlineChannel?.name);

    onlineChannel.here((users) => {
      // console.log("here", users);
    });
    onlineChannel.joining((event) => {
      // console.log(`${event.user.name} has joined the channel.`);
      //   console.log(users);
    });

    onlineChannel.leaving((event) => {
      // console.log(`${event.user.name} has left the channel.`);
    });

    // onlineChannel.on("presence:subscribed", (members) => {
    //   console.log(members)
    // //  callback(members.map((m) => m.user_info));
    // });

    onlineChannel.on("connect_error", function (error) {});
  }, [location]);

  useEffect(() => {
    // This effect will run whenever the location changes
    // console.log('Location changed to:', location.pathname);
    dispatch(toggleNotification(false));
    dispatch(toggleSetting(false));
    // You can perform any actions you want based on the location change
    // For example, update state, fetch data, or trigger specific logic.
  }, [location]);

  const rejectCall = (e, data, user) => {
    e.preventDefault();

    var videoData = {
      call_id: data?.data?.call_id,
      user_id: user?.main?.id,
      chat_id: data?.data?.chat_id,
    };

    clearInterval(incomingCallTimer);
    incomingCallCount = 0;
    // Handle rejecting the video call here
    // console.log("Rejected video call with callId: ", data?.data?.caller_id);

    //
    try {
      AxiosWithAuth()
        .post(`${url}/videochats/decline-call`, videoData)
        .then((response) => {
          // console.log(response);

          if (document.getElementById("call-popup"))
            document.getElementById("call-popup").classList.remove("active");

          if (document.getElementById("callnotification"))
            document
              .getElementById("callnotification")
              .classList.remove("active");
        })
        .catch((error) => {
          // console.log(error);

          if (document.getElementById("call-popup"))
            document.getElementById("call-popup").classList.remove("active");

          if (document.getElementById("callnotification"))
            document
              .getElementById("callnotification")
              .classList.remove("active");
        });
    } catch (e) {}
  };

  function getGlobalNotifications(read) {
    var data = {
      user: profileData?.main?.id,
      read: read,
      limit: 30,
      page: 0,
    };
    // console.log('notificattion')

    dispatch(getNotifications(data));

    // console.log(notificationsData);
    // console.log(notificationsData?.unread_messages);
  }

  useEffect(() => {
    var user = {
      user: profileData?.main?.id,
    };

    dispatch(getNotificationsCount(user));
  }, [notificationsData]);

  const handleImageError = (e) => {
    // Replace the failed image with a placeholder image
    e.target.src = imagePlacholder; // Replace with the path to your placeholder image
  };

  async function handleFilterToggle(e) {
    e.preventDefault();

     await setfilterSlider(sidebarTogggle);
    dispatch(updateSidebarToggle(!sidebarTogggle));

  }

 

  const releaseMessages = () => {
    var user = {
      user_id: profileData?.main?.id,
    };
    try {
      axiosWithAuth()
        .post(`${url}/chats/clear-current-chat`, user)
        .then((res) => {});
    } catch (error) {}
  };

  useEffect(() => {


    return () => {
      if (connectedMessage){
        dispatch(updateMessageConnectesStatus(false))
        releaseMessages()
        
      } 
    };
  }, [location, connectedMessage]);

  return (
    <>
 { console.log("loading topbar page  popup : "+profileData?.main?.email) }
 {/* old code */}
    {/* {(profileData?.show_email_verification || profileData?.show_phone_verification) && 
    <VerificationPopup profileData={profileData} /> 
    } */}
    
    {(profileData?.show_email_verification || profileData?.show_phone_verification) ? (
        <VerificationPopup profileData={profileData} />
      ) : (
        (() => {
          console.log("No verification needed for: show_email_verification " + profileData?.show_email_verification);
          console.log("No verification needed for: " + profileData?.main?.email);
          // return <div>No verification needed at this time.</div>;
        })()
      )}


      {!disablePopups && (
        <>
          {/* <PremiumPopup profileData={profileData} />
          <IphonePopup /> */}
          {/* <GhostPopup profileData={profileData} /> */}
        </>
      )}

      <div id="callnotification">{calData}</div>
      <Link to="/" id="mobile-logo"></Link>

      <div id="sticky-bar">
        <div>
        {hasIncognito && (
          <div className="toggle-button-area">
            <GhostToggle />
          </div>
        )}
        {profileData?.is_premium ? (
          ""
        ) : (
          <div id="premium-link" className="custom-link nolinkstyle text-black">
            <Link to="/premium">{t(["upgrade-link", ""])} </Link>
          </div>
        )}
      </div>
        <div id="active-users">
          <span className="count">{liverUsers}</span>
          <div className="label">
            {language === "en" ? <>live users</> : <>लाइव उपयोगकर्ता</>}
          </div>
        </div>
      </div>

      <div id="topbar">
        {location.pathname === "/" ? (
          <>
            <div
              id="filterbt"
              className="icon-only"
              onClick={(e) => {
                handleFilterToggle(e);
              }}
            >
              <div className="filter icon"></div>
            </div>
          </>
        ) : (
          <></>
        )}

        <div id="notifications" className="icon-only">
          <div className="bell icon" onClick={handleNotificationToggle}>
            {notificationsCounter?.unread_notifications > 0 ? (
              <>
                <div className="count">
                  {notificationsCounter?.unread_notifications}
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div id="account-badge" className={settingsToggle ? "active" : ""}>
          <div
            className={`user-photo ${
              profileData?.is_premium ? " premium" : ""
            } `}
          >
            <Link to="/profile">
              <img
                src={profileData?.photo ? profileData?.photo : imagePlacholder}
                alt={"image-" + profileData?.profile?.first_name}
                onError={handleImageError}
              />
            </Link>
          </div>
          <div className="badge-new">
            <div
              className={`name custom-link profile-image-custom ${
                settingsToggle ? "active" : ""
              }`}
              onClick={handleSettingsToggle}
              ref={topNavDropDownRef}
            >
              <span>
                {t(["greeting", ""])}{" "}
                {capitalizeWords(profileData?.profile?.first_name)}!
              </span>
            </div>
          </div>

          <div
            id="badge-menu"
            className="clearfix"
            onClick={handleSettingsToggle}
          >
            <ul>

              <li>

                <Link to="/profile" className="dropdown-nav-item profile">
                  {t(["my-profile-link", ""])}
                </Link>
              </li>
              <li>
                <Link to="/settings" className="dropdown-nav-item settings">
                  {t(["settings-link", ""])}
                </Link>
              </li>
              <li className="display-mobile-only">
            <Link to="/about-us"  className="dropdown-nav-item about-us">
              {t(["about-us-link", ""])}
            </Link>
            </li>


              <li>
                <Link
                  to="/settings/?privacy=privacy_policy"
                  className="dropdown-nav-item privacy"
                >
                  {t(["privacy-link", ""])}
                </Link>
              </li>

              <li className="display-mobile-only">
                <Link to="/contact-us" className="dropdown-nav-item support ">
                  {t(["support-link", ""])}
                </Link>
              </li>


              <li className="display-mobile-only">
                <Link
                  to="https://wa.me/971502470906"
                  target="_blank"
                  className="dropdown-nav-item whatsapp "
                >
                  {t(["whatsapp-link", "WhatsApp"])}
                </Link>
              </li>
              <li className="log-out-button">
                <Link
                  to="/"
                  className="dropdown-nav-item logout"
                  onClick={(e) => LogOut(e, profileData?.main?.id)}
                >
                  {t(["logout-button", ""])}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ProfileFrozen />
      <PaymentSuccessPopup />
    </>
  );
}
