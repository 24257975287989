import React, { useCallback, useState, useEffect, useRef } from "react";
import { flushSync } from "react-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axiosAuth from "../../app/auth/jwtMiddleware";
// import Echo from 'laravel-echo';
import Echo from "laravel-echo";
import io from "socket.io-client";
// import Socketio from 'socket.io-client';
// import {getRecentlyLiked,getChatHistory} from "../../"
import {
  getRecentlyLiked,
  getChatHistory,
  updateMessageConnectesStatus,
} from "../../app/actions/Messages";

import { updateSidebarToggle } from "../../app/actions/Global";
import {
  setUpdateNotifications,
  setUpdateChats,
  setUpdateNotificationsCounter,
} from "../../app/actions/Notifications";
// import { getProfileData, getEditData } from "../../app/actions/Profile";

import { useTranslation } from "react-i18next";
import { capitalizeWords } from "../../app/utilities/functions";
import {
  getLanguageFromLocalStorage,
  savePageTranslateLocalStorage,
  initializeI18n,
} from "../../app/utilities/localization";

import placeholderImage from "../../assets/images/Main/user-placeholder.jpg";

import "./messages.scss";
import RecentlyLiked from "./recentlyliked";
import UserDetails from "./userDetails";
import { showAlert } from "../../components/showAlerts";
import "simplebar-react/dist/simplebar.min.css";
import { Tooltip } from "react-tooltip";
// import  ShowIncomingCall  from "./videoCallPopup";

import {
  sendVideoCallRequest,
  acceptVideoCall,
  rejectVideoCall,
  deactivateVideoCallMode,
  endVideoCall,
  validateCall,
  acceptCallFromExternal,
  rejectVideoCallExternal,
} from "./videoFunctions";

import Emotes from "./emotes";

import AdOne from "../../assets/images/Main/video-call-icon.svg";
import closeBtn from "../../assets/images/Main/close-icon.svg";
import videoCall from "../../assets/images/Main/video-sleep.png";

const url = process.env.REACT_APP_PUBLIC_URL;

export default function Messages() {
  const { t } = useTranslation();
  const language = getLanguageFromLocalStorage() || "en";

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { profileData } = useSelector((state) => state.profile);
  const inputRef = useRef(null);
  //   const echo = new Echo()

  //
  //
  const [recently, setRecentlyLiked] = useState([]);
  const [recentlyLikedMore, setRecentlylikedMore] = useState([]);
  const [allRecentlyLoaded, setAllRecentlyLoaded] = useState(false);
  const [chatpinned, setChatChatPinned] = useState([]);
  const [chatunpinned, setChatUnpinned] = useState([]);
  const [chat, setChat] = useState();
  const [chatmessage, setChatMessage] = useState([]);
  const [chatmessageobject, setChatMessageObject] = useState([]);

  //
  const [valuemessage, setValueMessage] = useState("");
  const [reportReason, setReportReason] = useState("");

  const [currentChatmateProfile, setCurrentChatmateProfile] = useState();
  const [chatsingle, setChatSingle] = useState();
  //

  const { recentlyLiked, chatHistory, ischathistoryLoading } = useSelector(
    (state) => state.messages
  );
  const { notificationsData, notificationsCounter } = useSelector(
    (state) => state.notifications
  );
  const [showchatoption, setShowChatOption] = useState(false);
  const [ischatpinned, setIsChatPinned] = useState(false);
  const [blockuserpopup, setBlockUserPopUp] = useState(false);
  const [reportuserpopup, setReportUserPopup] = useState(false);
  const [mutecall, setMuteCall] = useState(false);
  const [recentlylikedpopup, setRecentlyLikedPopUp] = useState(false);

  //
  const [videoCallAvailable, setVideoCallAvailable] = useState(false);

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isChat, setIsChat] = useState(false);
  const [chatBoxHeight, setChatBoxHeight] = useState("100vh");

  //   const []

  const [lastMessageTimestamp, setLastmessageTimeStamp] = useState(null);

  const [page, setPage] = useState(0);

  const [currentUserTo, setCurrentUserTo] = useState("");
  const [currentUserToObject, setCurrentUserToOject] = useState("");
  const [currentChatId, setCurrentChatId] = useState(null);
  const [chatlimit, setChatLimit] = useState(30);

  const [timestamp, setTimeStamp] = useState("");

  // const [chatmessagecounter, setCounter]

  const [videochatdata, setVideoChatdata] = useState();
  const simpleChatBarRef = useRef(null);
  const messagesBoxRef = useRef(null);
  const LikesRef = useRef(null);
  const emoRef = useRef(null);

  const [previousScrollTop, setPreviousScrollTop] = useState(0);
  const [previousHeight, setPreviousSHeight] = useState(0);
  const [currentMessageEmoPopup, setCurrentMessageEmoPopup] = useState("");

  const [allmessagesloaded, setAllMessages] = useState(null);

  const [callId, setCallId] = useState();
  const [callrId, setCallrId] = useState();
  const [callconnected, setCallConnected] = useState(false);

  const [mainEmote, setMainEmote] = useState(false);
  const [videocallactive, setVideCallActive] = useState(false);

  const currentChatIdRef = useRef(currentChatId);
  const currentUnpinned = useRef(chatunpinned);
  const currentpinned = useRef(chatpinned);
  const chatMesageRef = useRef(chatmessageobject);
  const currentUserToRef = useRef(currentUserToObject);

  const [getMoreRecentkyLiked, setGetMoreRecentlyLiked] = useState(false);

  const [loadedImagesCount, setLoadedImagesCount] = useState(0);
  const [imagesLoaded, setIsImagesLoaded] = useState(false);
  //
  //
  const [showadd, setShowAdd] = useState(false);
  const [addshown, setShownAdd] = useState(false);

  const AddTopBackgroundDtyle = {
    backgroundImage: `url(${AdOne})`,
    backgroundSize: "cover",
  };

  useEffect(() => {
    const input = inputRef.current;
  
    const handleFocus = () => {
      if (document.activeElement !== input) {
        input.focus();
      }
    };
  
    if (input) {
      input.addEventListener('blur', handleFocus);
    }
  
    return () => {
      if (input) {
        input.removeEventListener('blur', handleFocus);
      }
    };
  }, []);

  useEffect(() => {
    // setTimeout(() => {
    //   window.scrollTo(0,0)
    // }, 300);
    function handleClickOutside(event) {
      const isEmoteButton = event.target.closest(".user-emo-popup");
      // console.log(event.target.classList);

       console.log(event.target.classList[0]);
      if (emoRef.current && !emoRef.current.contains(event.target)) {
        document.getElementById("mainmessage").classList.add("hidden");
      }

      if(event.target.classList[0] !== "others" && 
      event.target.classList[0] !== "pin" && 
      event.target.classList[0] !== "mute" && 
      event.target.classList[0] !== "delete" && 
      event.target.classList[0] !== "block" &&
      event.target.classList[0] !== "report" 
      ){
       
        setShowChatOption(false);
      }
      if (
        event.target.classList[0] !== "emote-popup" &&
        event.target.classList[0] !== "emoteBt"
      ) {
        if (currentMessageEmoPopup !== "") {
          const popupElement = document.getElementById(currentMessageEmoPopup);
          if (popupElement) {
            popupElement.classList.add("hidden");
          }
          setCurrentMessageEmoPopup("");
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [emoRef, currentMessageEmoPopup, setCurrentMessageEmoPopup]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);

    window.addEventListener("popstate", (e) => {
      // Nope, go back to your page
      window.location.reload(true);
    });

    // Clean up the event listener when the component unmounts
    return () => {
      // window.addEventListener("popstate", e => {
      //   // Nope, go back to your page
      //   console.log(window.history)
      //   // e.preventDefault()
      //   window.location.reload(false);
      //   // navigate('/messages')
      //   // this.props.history.go(1);
      // });
    };
  }, [location, navigate]);

  //
  //
  const withEmojis = /\p{Emoji}/u;

  var emotes = [
    "wow",
    "inlove",
    "heart",
    "smile",
    "lol",
    "sad",
    "wink",
    "laugh",
    "shock",
    "tongue",
  ];
  var emotesShortCodes = [];

  for (var x = 0; x < emotes.length; x++) {
    emotesShortCodes.push("![emote-" + emotes[x] + "]!");
  }

  var chatBox = document.getElementById("chatbox");

  //

  useEffect(() => {
    const fetchData = async () => {
      await savePageTranslateLocalStorage("messages");
      initializeI18n(["premium"]);
    };
    fetchData();

    var formData = new FormData();

    formData.append("user", profileData?.main?.id);
    formData.append("page", page);
    formData.append("limit", 5);

    dispatch(getRecentlyLiked(formData));
    getuserChatHistory();
  }, [profileData?.main?.id, page]);

  useEffect(() => {
    if (recently?.length) {
      if (recently?.length > 4) {
        if (loadedImagesCount >= 4) {
          setIsImagesLoaded(true);
        }
      } else if (loadedImagesCount >= recently?.length) {
        setIsImagesLoaded(true);
      } else {
        setIsImagesLoaded(false);
      }
    }
  }, [loadedImagesCount]);

  function handleImageLoad() {
    setLoadedImagesCount((prevCount) => prevCount + 1);
  }

  const loadMoreLikedProfiles = () => {
    var formData = new FormData();
    formData.append("user", profileData?.main?.id);
    formData.append("page", page);
    formData.append("limit", 8);
    // setPage((num) => num + 1)
    getRecentlyLikedMore(formData);
    // dispatch(getRecentlyLiked(formData))
  };

  //

  const getRecentlyLikedMore = (value) => {
    // console.log(value)
    try {
      axiosAuth()
        .post(`${url}/users/get-liked-users`, value)
        .then(({ data }) => {
          if (data?.status === 200) {
            if (data?.data?.length <= 6) {
              setAllRecentlyLoaded(true);
            }
            setRecentlylikedMore((prevRecentlyMore) => [
              ...(prevRecentlyMore || []), // Make sure prevRecentlyMore is an array
              ...(data?.data || []), // Make sure isRecentlyLoadingMore is an array
            ]);
            // dispatch(initializeRecentlyLiked(data?.data));
          }
        })
        .catch((error) => {
          if (error?.code === "ERR_NETWORK") {
            showAlert(error?.message);
          }
          if (error?.response?.data?.status === 422) {
            showAlert(error?.response?.data?.data);
          } else {
            showAlert(error?.message);
          }
        });
    } catch (error) {
      showAlert(error?.message);
    }
  };

  useEffect(() => {
    const likeRef = LikesRef.current;

    const handleScroll = () => {
      if (likeRef) {
        const isAtBottom =
          likeRef.scrollTop + likeRef.clientHeight >= likeRef.scrollHeight;

        // console.log(allRecentlyLoaded)
        if (isAtBottom && !allRecentlyLoaded) {
          const formData = new FormData();
          formData.append("user", profileData?.main?.id);
          formData.append("page", page + 1);
          formData.append("limit", 8);

          setPage((prevPage) => prevPage + 1); // Update the page directly
          getRecentlyLikedMore(formData);
        }
      }
    };

    if (likeRef) {
      likeRef.removeEventListener("scroll", handleScroll);
      likeRef.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (likeRef) {
        likeRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, [page, dispatch, profileData]);

  //

  useEffect(() => {
    currentUnpinned.current = sortChats(chatHistory?.data?.unpinned);
    currentpinned.current = sortChats(chatHistory?.data?.pinned);
    // console.log(sortChats(chatHistory?.data?.unpinned))
  }, [chatHistory]);

  useEffect(() => {
    currentUnpinned.current = sortChats(chatunpinned);
    currentpinned.current = sortChats(chatpinned);
  }, [chatunpinned, chatpinned, currentChatId]);



  function sortChats(data){


    if(data){
    let sortedData = [...data];
        sortedData?.sort((a, b) => {
      var as = new Date(a?.updated_at).getTime(),
       db = new Date(b?.updated_at).getTime();

      return db - as;
    });

    return sortedData;
  }
  return data
  }

  useEffect(() => {
    chatMesageRef.current = chatmessageobject;
  }, [chatmessageobject]);

  useEffect(() => {
    if (chatHistory?.data) {
      //   console.log("heeeere");
      setChatChatPinned(chatHistory?.data?.pinned);
      setChatUnpinned(chatHistory?.data?.unpinned);

      //
      const url = new URL(window.location.href);
      // Access the query parameters using URLSearchParams
      const queryParams = new URLSearchParams(url.search);
      // Get the value of the "u" query parameter
      const uValue = queryParams.get("u");
      const callId = queryParams.get("cid");
      const callrId = queryParams.get("clrd");

      // console.log(uValue)
      // console.log(callId)

      // Do something with the "u" value
      //

      if (callId && !callconnected) {
        setCallConnected(true);
        handleAcceptVideoCallFromExternal(callId, uValue);
        queryParams.delete("cid");
        queryParams.delete("clrd");
      }

      if (chatHistory?.data?.pinned) {
        // console.log(currentpinned?.current)
        // console.log(currentChatIdRef.current)
        if (uValue) {
          setIsChat(true);
          // checkScreenSize()
          setCurrentChatId(uValue);
          dispatch(setUpdateChats(uValue));
          setTimeout(() => {
            queryParams.delete("u");
            const newURL = `${window.location.pathname}`;
            window.history.replaceState(null, null, "/messages");
            // console.log(window.history);
            // Update the URL without a page refresh
            // window.history.pushState({}, document.title, newURL);  //uncomment this
          }, 3500);
        }
        // else if(currentChatId){

        // }
        // else if(!currentChatIdRef.current){

        // }
        else {
          if (!currentChatIdRef.current) {
            console.log("kjkjkjk");
            setCurrentChatId(currentpinned?.current[0]?.chat_id);
            dispatch(setUpdateChats(currentpinned?.current[0]?.chat_id));
          }
        }
        //
      } else {
        if (uValue) {
          // console.log(uValue)
          setCurrentChatId(uValue);
          dispatch(setUpdateChats(uValue));
          // console.log('heeeet');
          setIsChat(true);
          // checkScreenSize()
          setTimeout(() => {
            queryParams.delete("u");
            const newURL = `${window.location.pathname}`;
            // Update the URL without a page refresh
            window.history.replaceState(null, null, "/messages");
            // console.log(window.history);
            // window.history.pushState({}, document.title, newURL);   //uncomment this
          }, 3500);

          //
        } else {
          if (!currentChatIdRef.current) {
            setCurrentChatId(chatHistory?.data?.unpinned[0]?.chat_id);
            dispatch(setUpdateChats(chatHistory?.data?.unpinned[0]?.chat_id));
          }
        }
      }
      dispatch(updateMessageConnectesStatus(true));
    } else {
      console.log("else");
      setChatChatPinned();
      setChatUnpinned();
      setChat();
    }
  }, [chatHistory]);

  function getuserChatHistory() {
    var userdata = new FormData();
    userdata.append("user", profileData?.main?.id);
    dispatch(getChatHistory(userdata));
  }

  useEffect(() => {
    setRecentlyLiked(recentlyLiked);
  }, [recentlyLiked]);

  useEffect(() => {
    window.Echo = new Echo({
      broadcaster: "socket.io",
      host: process.env.REACT_APP_LARAVEL_CHANNEL_HOST,
      client: io,
      transports: ["websocket"],
      wsHost: process.env.REACT_APP_LARAVEL_CHANNEL_HOST_NOPORT,
      // wsHost:window.location.hostname,
      wsPort: 6001,
    });

    window.Echo.connector.socket.on("connect", function (socket) {
      console.log("on connect");
      // console.log(socket);
    });

    window.Echo.connector.connect(); // Connect to the Echo server

    window.Echo.connector.socket.on("connect_error", function (error) {
      console.log(error);
      // console.log(error.description);
      console.log("connect error");
    });
    return () => {
      // Clean up the Echo instance when the component unmounts
      window.Echo.connector.disconnect();
      // console.log(videocallactive);
      if (videocallactive) {
        rejectVideoCallExternal(videochatdata, profileData, currentChatId);
      }
      // console.log("On Call");
      // if(isOnCall){

      // }
    };
  }, []);

  const handleImageError = (e) => {
    // Replace the failed image with a placeholder image
    e.target.src = placeholderImage; // Replace with the path to your placeholder image
  };

  const selectUserChat = (e, value, dataVlaue, chatsData, chatsType) => {
    var targetEl = e.target;
    if (!targetEl.classList.contains("custom-link")) {
      targetEl = targetEl.closest("li");
    }

    if (!targetEl.classList.contains("read")) {
      setTimeout(() => {
        targetEl.classList.add("read");
      }, 0);

      var countdata = {
        ...notificationsCounter,
        unread_messages: notificationsCounter?.unread_messages - 1,
      };
      // dispatch(setUpdateNotifications(data));
      dispatch(setUpdateNotificationsCounter(countdata));
      // console.log(countdata)
      // dispatch(setUpdateNotifications([]))
    }
    // setCurrentChatId();

    if (currentChatIdRef.current !== value) {
      window.Echo.connector.leave(currentChatIdRef.current);
      setChat([]);
      setCurrentChatmateProfile([]);
      dispatch(setUpdateChats(value));
      setAllMessages(null);
      setCurrentChatId(value);
    }

    var newchats = updateMessageRead(chatsData, value);
    if (chatsType === "pinned") {
      setTimeout(() => {
        setChatChatPinned(newchats);
      }, 0);
    } else {
      setTimeout(() => {
        setChatUnpinned(newchats);
      }, 0);
    }
    setIsChat(true);
  };

  const ChatMessageReceived = (data) => {
    if (data?.data?.chat_request_id === currentChatIdRef.current) {
      const index = currentUnpinned?.current?.findIndex(
        (chat) => chat.chat_id === data?.data?.chat_request_id
      );
      const indexpinned = currentpinned?.current?.findIndex(
        (chat) => chat.chat_id === data?.data?.chat_request_id
      );

      if (index >= 0) {
        // console.log(data?.data)
        var newunpinedmsg = [];
        // chatunpinned[index].unread=true
        newunpinedmsg = chatHistoryMessage(
          currentUnpinned?.current,
          index,
          data
        );
        // console.log(chatunpinned[index])
        setTimeout(() => {
          setChatUnpinned(newunpinedmsg);
        }, 0);
      } else if (indexpinned >= 0) {
        var pinned = [];
        pinned = chatHistoryMessage(currentpinned?.current, indexpinned, data);
        setTimeout(() => {
          setChatChatPinned(pinned);
        }, 0);
      }

      setChatMessageObject((chats) => [data?.data, ...chats]);
      var htmlrece = generateMessageHTML(data?.data, data?.data?.sender_name);
      //  console.log(isValid); // true
      setChatMessage((msgs) => [htmlrece, ...msgs]);
    } else {
      const unredindexunpinned = currentUnpinned?.current?.findIndex(
        (chat) => chat.chat_id === data?.data?.chat_request_id
      );

      if (unredindexunpinned >= 0) {
        var newunpinednew = [];

        newunpinednew = chatHistoryNoIndex(currentUnpinned?.current, data);
        setTimeout(() => {
          setChatUnpinned(newunpinednew);
        }, 0);
      } else {
        var newpinnednew = [];
        newpinnednew = chatHistoryNoIndex(currentpinned?.current, data);

        setTimeout(() => {
          setChatChatPinned(newpinnednew);
        }, 5);
      }
    }
  };

  function updateEmoji(data, index) {
    let newmessage = [];
    newmessage = chatMesageRef?.current?.map((mgs, i) => {
      if (i === index) {
        return {
          ...mgs,
          emote: {
            chat_id: data?.chat_id,
            type: data?.type,
            user_id: data?.user_id,
          },
        };
      } else {
        return mgs;
      }
    });

    return newmessage;
  }

  useEffect(() => {
    currentChatIdRef.current = currentChatId;
  }, [currentChatId]);



  useEffect(() => {
    currentUserToRef.current = currentUserToObject;
  }, [currentUserToObject]);

  useEffect(() => {
    var currentUserId = "";
    if (currentChatIdRef.current && currentUserTo) {
      currentUserId = "";
      currentUserId = currentChatIdRef.current;
      if (
        !window.Echo.connector.channels[
          "user-" + profileData.profile.slug + "-chat-channel"
        ]
      ) {
        window.Echo.connector
          .channel("user-" + profileData.profile.slug + "-chat-channel")
          .on("UserEvent", (data) => {
            if (data.type === "user-blocked-you") {
              // console.log("blocked");
              if (data.data.user_id === currentUserTo) {
                // console.log('jdsdsjdhj')
                window.Echo.connector.leave(currentUserId);
                setTimeout(() => {
                  setCurrentChatId();
                  dispatch(setUpdateChats());
                }, 150);
                getuserChatHistory();

                // $('#messages-box').empty();
                //   setCurrentChatmateProfile();
              }
            } else if (data.type === "user-unblocked-you") {
              // console.log("unblocked");
              getuserChatHistory();
              getChatMessages();
            } else if (data?.type === "chat-message-received") {
              // console.log('chat-received')
              ChatMessageReceived(data);
            } else if (data?.type === "emote-received") {
              // console.log("emote");
              // var chatElement = document
              //   .getElementById("messages-box")
              //   .querySelector(".chat-" + data?.data?.chat_id);
              // console.log(chatElement);
              // if (chatElement) {
              //   if (chatElement.querySelector(".emote")) {
              //     chatElement.querySelector(".emote").classList =
              //       "emote " + data.data.type;
              //   } else {
              //     const timeElement = chatElement.querySelector(".time");
              //     if (timeElement) {
              //       const emoteButton = document.createElement("button");
              //       emoteButton.className = "emote " + data.data.type;
              //       timeElement.appendChild(emoteButton);
              //     }
              //   }
              // }
              // notificationSound('pop');
            }
          })
          .on("VideoChatReceived", (data) => {
            if (data?.type === "video-chat-request") {
              // console.log(data);
              setVideoChatdata(data);
              validateCall(
                data?.data?.call_id,
                profileData,
                data?.data?.caller_id,
                data?.data?.chat_id
              );
              //

              // notificationSound('ring');
            }
            if (data?.type === "video-chat-declined") {
              deactivateVideoCallMode();
              // setCurrentChatId(null)
            }
            if (data?.type === "video-chat-unanswered") {
              // console.log("video-chat-unanswered");
              deactivateVideoCallMode();
              document.getElementById("call-popup").classList.remove("active");
              // setCurrentChatId(null)
            }
            if (data?.type === "video-chat-ended") {
              // console.log("video-chat-ended");
              deactivateVideoCallMode();
              document.getElementById("call-popup").classList.remove("active");
              // setCurrentChatId(null)
            }
          });
      }

      // channnel connector
      if (!window.Echo.connector.channels[currentUserId]) {
        window.Echo.connector
          .channel(currentUserId)
          .on("UserEvent", (response) => {
            // console.log(messagesrec)
            if (response?.type === "chat-message-sent") {
              if (response?.data?.from_user_id === profileData?.main?.id) {
                setValueMessage("");
                var htmlsent = generateMessageHTML(
                  response?.data,
                  response?.data?.receiver_name
                );

                setTimeStamp("");
                const index = currentUnpinned?.current?.findIndex(
                  (chat) => chat.chat_id === response?.data?.chat_request_id
                );
                const indexpinned = currentpinned?.current?.findIndex(
                  (chat) => chat.chat_id === response?.data?.chat_request_id
                );
                if (index >= 0) {
                  // chatunpinned[index].unread=true
                  var newunpined = chatHistoryMessage(
                    currentUnpinned?.current,
                    index,
                    response
                  );
                  // console.log(chatunpinned[index])
                  setTimeout(() => {
                    setChatUnpinned(newunpined);
                  }, 0);
                } else if (indexpinned >= 0) {
                  var pinnedmnew = chatHistoryMessage(
                    currentpinned?.current,
                    indexpinned,
                    response
                  );

                  setTimeout(() => {
                    setChatChatPinned(pinnedmnew);
                  }, 0);
                }
                setChatMessageObject((chats) => [response?.data, ...chats]);
                setTimeout(() => {
                  setTimeStamp(response?.data?.date);
                  setChatMessage((chatmessage) => [htmlsent, ...chatmessage]);
                }, 10);
              }
            }

            if (response.type === "emote-received") {
              // console.log("566");

              // // console.log(chatmessage);
              // console.log(response)
              // console.log(chatMesageRef?.current);

              const index = chatMesageRef?.current?.findIndex(
                (chat) => chat.id === response.data.chat_id
              );
              var nem = updateEmoji(response?.data, index);
              setChatMessageObject(nem);
              var HTML = [];
              for (const [dataKey, dataValue] of Object.entries(nem)) {
                var html = generateMessageHTML(dataValue);
                HTML.push(html);
              }
              setChatMessage(HTML);
            }
          });
      }
    }
  }, [currentUserTo, currentChatId]);

  useEffect(() => {
    if (currentChatId) {
      // getuserChatHistory();
      getChatMessages();
    }
  }, [currentChatId, language]);

  //   console.log(chathistory);

  function getChatMessages() {
    if (currentChatIdRef.current) {
      var chatData = new FormData();
      chatData.append("chat_id", currentChatIdRef.current);
      chatData.append("user_id", profileData?.main?.id);
      chatData.append("limit", chatlimit);
      chatData.append("language", language);
      getMessages(chatData);
    }
  }

  function chatHistoryMessage(chats, index, data) {
    let newunpined = [];
    newunpined = chats?.map((chat, i) => {
      if (i === index) {
        return {
          ...chat,
          last_updated: data?.data?.date,
          updated_at: data?.data?.updated_at,
          unread: false,
          latest_message: {
            ...chat.latest_message,
            chat_message: data?.data?.chat_message,
            updated_at: data?.data?.updated_at,
            from_user_id: data?.data?.from_user_id,
          },
        };
      } else {
        return chat;
      }
    });

    newunpined?.sort((a, b) => {
      // Function to get the timestamp from the latest_message or a default value
      const getTimestamp = (item) => {
        return item?.latest_message?.updated_at
          ? new Date(item.latest_message.updated_at).getTime()
          : Number.MAX_SAFE_INTEGER; // Send undefined to the bottom
      };

      // Get timestamps for a and b
      var timestampA = getTimestamp(a);
      var timestampB = getTimestamp(b);

      // Sort in descending order based on timestamps
      return timestampB - timestampA;
    });

    return newunpined;
  }

  function chatHistoryNoIndex(chats, data) {
    let newunpined = [];
    newunpined = chats?.map((chat) => {
      if (chat?.chat_id === data?.data?.chat_request_id) {
        return {
          ...chat,
          last_updated: data?.data?.date,
          updated_at: data?.data?.updated_at,
          unread: true,
          latest_message: {
            ...chat.latest_message,
            chat_message: data?.data?.chat_message,
            updated_at: data?.data?.updated_at,
            from_user_id: data?.from_user_id,
          },
        };
      } else {
        return chat;
      }
    });
    newunpined?.sort((a, b) => {
      var as = new Date(a?.latest_message?.updated_at).getTime(),
        db = new Date(b?.latest_message?.updated_at).getTime();

      return db - as;
    });

    return newunpined;
  }

  function updateMessageRead(chats, chatId) {
    let newunpined = [];
    newunpined = chats?.map((chat) => {
      if (chat?.chat_id === chatId) {
        return {
          ...chat,
          unread: false,
        };
      } else {
        return chat;
      }
    });

    return newunpined;
  }

  useEffect(() => {
    if (isChat) {
      if (isSmallScreen) {
        setTimeout(() => {
          if (document.getElementById("topbar")) {
            document.getElementById("topbar").style.display = "none";
          }
          if (document.getElementById("mobile-logo")) {
            document.getElementById("mobile-logo").style.display = "none";
          }
          if (document.getElementById("main")) {
            document.getElementById("main").classList.add("remove-before");
          }
          if (document.querySelector("#main-content.full")) {
            document
              .querySelector("#main-content.full")
              .classList.add("main-padding-mobile");
            document.getElementById("bottombar").style.display = "none";
          }
        }, 0);
      }
    } else {
      if (isSmallScreen) {
        setTimeout(() => {
          if (document.getElementById("topbar")) {
            document.getElementById("topbar").style.display = "flex";
          }
          if (document.getElementById("mobile-logo")) {
            document.getElementById("mobile-logo").style.display = "block";
          }
          if (document.getElementById("main")) {
            document.getElementById("main").classList.remove("remove-before");
          }
          if (document.querySelector("#main-content.full")) {
            document
              .querySelector("#main-content.full")
              .classList.remove("main-padding-mobile");
            document.getElementById("bottombar").style.display = "flex";
          }
        }, 0);
      }
      else{
        setTimeout(() => {
          if (document.getElementById("mobile-logo")) {
            document.getElementById("mobile-logo").style.display = "none";
          }
          if (document.getElementById("main")) {
            document.getElementById("main").classList.add("remove-before");
          }
          if (document.querySelector("#main-content.full")) {
            document
              .querySelector("#main-content.full")
              .classList.add("main-padding-mobile");
            document.getElementById("bottombar").style.display = "none";
          }
        }, 0);
      }
    }

    checkScreenSize();

    window.addEventListener("resize", checkScreenSize);

    return () => {
      //
      //
      if (!isSmallScreen) {
      if (document.getElementById("bottombar")) {
        document.getElementById("bottombar").style.display = "none";
      }
      if (document.getElementById("mobile-logo")) {
        document.getElementById("mobile-logo").style.display = "none";
      }
      window.removeEventListener("resize", checkScreenSize);
    };
  }
  }, [isChat, isSmallScreen]);

  const checkScreenSize = () => {
    var height;

    var shownAddFloat = localStorage.getItem("floatingMessageAdd");

    if (window.innerWidth < 780) {
      //
      if (!shownAddFloat && !addshown) {
        setTimeout(() => {
          setShowAdd(true);
          localStorage.setItem("floatingMessageAdd", true);
          setShownAdd(true);
        }, 3000);
      }

      dispatch(updateSidebarToggle(false));
      setIsSmallScreen(true);
      height = window.innerHeight - 10;
      // console.log(window.innerHeight);
    } else {
      setShowAdd(false);
      localStorage.setItem("floatingMessageAdd", false);
      setShownAdd(false);
      dispatch(updateSidebarToggle(true));
      setIsSmallScreen(false);
      height = window.innerHeight - 215;
    }

    // if()
    setChatBoxHeight(height + "px");
  };

  const chatBack = (e) => {
    e.preventDefault();
    setIsChat(false);

  };

  function closePopups(e) {
    // console.log(e);
    e.preventDefault();
    setBlockUserPopUp(false);
    setReportUserPopup(false);
    setShowChatOption(false);
    setRecentlyLikedPopUp(false);
    document.getElementById("non-premium-popup").classList.remove("active");
    document.getElementById("busy-popup").classList.remove("active");
  }

  function showReportUser(e) {
    e.preventDefault();
    setReportUserPopup(!reportuserpopup);
    setShowChatOption(false);
  }

  //
  //
  function reportUser(e, data) {
    e.preventDefault();

    // setReportUserPopup(false);
    if (data === "") {
      alert("Please provide details on the report");
      return;
    } else {
      var dataValue = {
        user_id: profileData?.main?.id,
        blocked_user_id: currentUserTo,
        report: data,
        language: language,
      };

      try {
        axiosAuth()
          .post(`${url}/chats/report-user`, dataValue)
          .then((res) => {
            if (res?.status === 200) {
              // console.log(e);
              setReportUserPopup(false);
              setCurrentChatId();
              dispatch(setUpdateChats());
              getuserChatHistory();
              setReportReason("");
            }
          })
          .catch((error) => {
            if (error?.code === "ERR_NETWORK") {
              showAlert(error?.message);
            }
            if (error?.response?.data?.status === 422) {
              showAlert(error?.response?.data?.data);
            } else {
              showAlert(error?.message);
            }
          });
      } catch (error) {
        // console.log(error);
      }
    }

    // e.preventDefault()
  }

  function blockChat(e) {
    e.preventDefault();
    setValueMessage("");
    // console.log(e)
    if (currentUserTo) {
      var data = {
        user_id: profileData?.main?.id,
        blocked_user_id: currentUserTo,
        language: language,
      };
      // console.log(currentUserTo);
      axiosAuth()
        .post(`${url}/chats/block-chat`, data)
        .then((res) => {
          if (res?.status === 200) {
            window.Echo.connector.leave(currentChatIdRef.current);
            setCurrentChatId();
            dispatch(setUpdateChats());
            getuserChatHistory();
            setValueMessage("");
            setBlockUserPopUp(false);
          } else {
          }
        });
    }
  }

  function showLikeUsersPopup(e) {
    e.preventDefault();
    setRecentlyLikedPopUp(!recentlylikedpopup);
    if (!getMoreRecentkyLiked) {
      setGetMoreRecentlyLiked(true);
      loadMoreLikedProfiles();
    }
  }

  //

  function sendNudge(e, name) {
    e.preventDefault();
    // console.log(profileData);
    // if (profileData?.is_premium) {
    var data = {
      from: profileData?.main.id,
      to: currentUserTo,
      chat_request_id: currentChatId,
      language: language,
      message: "![nudge]!",
    };
    try {
      axiosAuth()
        .post(`${url}/chats/send-message`, data)
        .then((res) => {
          // console.log(res);
          // const chatElement = document.getElementById(
          //   currentChatId + "-history"
          // );
          // if (chatElement) {
          //   const previewElement = chatElement.querySelector(".preview");
          //   if (previewElement) {
          //     // previewElement.innerHTML = msg.length > 20 ? msg.substring(0, 20) + "..." : msg;
          //   }
          //   const timestampElement = chatElement.querySelector(".timestamp");
          //   if (timestampElement) {
          //     // timestampElement.innerHTML = timestamp;
          //   }
          // }
          // getuserChatHistory();
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            showAlert(error?.response?.data?.message);
          } else if (error?.code === "ERR_NETWORK") {
            showAlert(error?.message);
          } else if (error?.response?.data?.status === 422) {
            showAlert(error?.response?.data?.data);
          } else {
            showAlert("We have encountered an error");
          }
          getuserChatHistory();
        });
    } catch (error) {
      getuserChatHistory();
      showAlert("We have encountered an error");
    }
    // } else {
    // console.log("error");
    // showAlert(t(['nudge-premium-only','']));
    // alert();
    // }
  }

  function chatOptions(e) {
    e.preventDefault();
    // console.log("shoe");
    setShowChatOption(!showchatoption);
  }

  function pinChat(e) {
    // toggleLoader(true);
    e.preventDefault();
    setShowChatOption(false);

    if (currentChatIdRef.current) {
      var data = {
        user_id: profileData?.main.id,
        chat_request_id: currentChatIdRef.current,
        language: language,
      };

      try {
        axiosAuth()
          .post(`${url}/chats/pin-chat`, data)
          .then((res) => {
            getuserChatHistory();
            setIsChatPinned(!ischatpinned);
            showAlert(res?.data?.message);
          })
          .catch((error) => {
            if (error?.response?.data?.message) {
              showAlert(error?.response?.data?.message);
            } else if (error?.code === "ERR_NETWORK") {
              showAlert(error?.message);
            } else if (error?.response?.data?.status === 422) {
              showAlert(error?.response?.data?.data);
            } else {
              showAlert("We have encountered an error");
            }
          });
      } catch (error) {
        showAlert("We have encountered an error");
      }
    }
  }

  function blockUser(e) {
    e.preventDefault();
    setShowChatOption(!showchatoption);
    setBlockUserPopUp(!blockuserpopup);
  }

  function muteCall(e) {
    e.preventDefault();
    setShowChatOption(false);
    setShowChatOption(false);

    try {
      var data = {
        user_id: profileData?.main?.id,
        muted_user_id: currentUserTo,
        language: language,
      };

      axiosAuth()
        .post(`${url}/videochats/mute-call`, data)
        .then((res) => {
          setMuteCall(!mutecall);
          if(mutecall) {
          showAlert('Call unmuted');
        } else {
          showAlert('Call muted');
        }
        });
    } catch (error) {
      showAlert(error?.response?.data?.message);
    }
  }

  function deleteChat(e) {

    e.preventDefault();
    // console.log("dele");

    if (currentUserTo) {
      setShowChatOption(false);

      var data = {
        user_id: profileData?.main?.id,
        chat_request_id: currentChatIdRef.current,
        language: language,
      };
      try {
        axiosAuth()
          .post(`${url}/chats/delete-chat`, data)
          .then((res) => {
            window.Echo.connector.leave(currentChatIdRef.current);
            setCurrentChatId(null);
            dispatch(setUpdateChats());
            setChatChatPinned();
            setChatUnpinned();
            getuserChatHistory();
          })
          .catch((error) => {
            if (error?.response?.data?.message) {
              showAlert(error?.response?.data?.message);
            } else if (error?.code === "ERR_NETWORK") {
              showAlert(error?.message);
            } else if (error?.response?.data?.status === 422) {
              showAlert(error?.response?.data?.data);
            } else {
              showAlert("We have encountered an error");
            }

            // console.log(error);
          });
      } catch (error) {
        showAlert("We have encountered an error");
        // console.log(e);
      }
    }
  }

  // console.log('outside')
  function sendEmote(messageId, type) {
    // e.preventDefault()
    // console.log(e)
    // console.log(messageId);
    // console.log(type);
    //  console.log(e)
    var data = {
      user_id: profileData?.main.id,
      chat_id: messageId,
      type: type,
      language: language,
    };
    try {
      axiosAuth()
        .post(`${url}/chats/store-emote`, data)
        .then((resp) => {
          // console.log(resp);
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            showAlert(error?.response?.data?.message);
          } else if (error?.code === "ERR_NETWORK") {
            showAlert(error?.message);
          } else if (error?.response?.data?.status === 422) {
            showAlert(error?.response?.data?.data);
          } else {
            showAlert("We have encountered an error");
          }
        });
    } catch (error) {
      showAlert("We have encountered an error");
    }
  }

  function sendMessage(e) {
    // console.log('message')
    e.preventDefault();

    var data = {
      from: profileData?.main.id,
      to: currentUserTo,
      chat_request_id: currentChatIdRef.current,
      message: valuemessage,
      language: language,
    };
    try {
      axiosAuth()
        .post(`${url}/chats/send-message`, data)
        .then((response) => {
          setValueMessage("");
          // console.log(response?.data)
          if (response?.data?.success) {
            // if (
            //   document
            //     .getElementById("chat-" + currentChatIdRef.current)
            //     .querySelector(".preview").innerHTML
            // ) {
            //   setValueMessage("");
            //   document
            //     .getElementById("chat-" + currentChatIdRef.current)
            //     .querySelector(".preview").innerHTML =
            //     valuemessage.length > 20
            //       ? valuemessage.substring(0, 20) + "..."
            //       : valuemessage;
            // }
            var chatElement = document.getElementById("chat-" + currentChatIdRef.current);
            if(chatElement){
            if (
              document
                .getElementById("chat-" + currentChatIdRef.current)
                .querySelector(".timestamp").innerHtml
            ) {
              setValueMessage("");
              document
                .getElementById("chat-" + currentChatIdRef.current)
                .querySelector(".timestamp").innerHTML = timestamp;
            }
          } }
        })
        .catch((error) => {
        
          if (error?.code === "ERR_NETWORK") {
            showAlert(error?.message);
          }
          if (error?.response?.data?.status === 422) {
            showAlert(error?.response?.data?.data);
          } else {
            showAlert("We have encountered an error");
          }
          getuserChatHistory();
          // console.log(error);
        });
    } catch (error) {
      getuserChatHistory();
      showAlert("We have encountered an error");
    }
  }

  function Msg(value, data) {
    if (value === currentChatIdRef.current) {
      // console.log('chat-'+value)
      var chat = document.getElementById("chat-" + value);
      //
      if (chat) {
        // chat.classList.add('read-mesage')
        // if (!chat.classList.contains("read")) {
        // console.log(chat)
        //  setTimeout(() => {
        //  }, 0);
        //  var dataNot = {
        //   ...notificationsData,
        //   unread_messages: notificationsData?.unread_messages - 1,
        //  }
        // dispatch(setUpdateNotifications(dataNot));
        // }
      }
    }

    var msg = "";
    if (data?.latest_message) {
      msg = data?.latest_message?.chat_message;
      msg = msg.length > 20 ? msg.substring(0, 20) + "..." : msg;
    } else {
      msg = "Chat started...";
    }
    switch (msg) {
      case "![nudge]!":
        if (data?.latest_message?.from_user_id !== profileData?.main?.id) {
          msg = data?.first_name + " " + t("nudged") + "!";
        } else {
          msg = t("nudge") + " " + data?.first_name + "!";
        }
        break;

      //
      case "![call-unanswered]!":
        msg = t("call-request-unanswered");
        break;
      //
      case "![call-started]!":
        msg = t("call-has-started");
        break;
      //
      case "![call-ended]!":
        msg = t("call-has-ended");
        break;
      //
      case "![call-declined]!":
        msg = t("call-declined");
        break;
      //
      default:
        msg = msg;
        break;
      // msg = msg;
    }

    if (emotesShortCodes.includes(msg)) {
      // console.log(emotes[emotesShortCodes.indexOf(msg)])
      msg = (
        <span
          key={`${data?.chat_id}-history-child`}
          id={`${data?.chat_id}-history-child`}
          className={`preview emoteIcon emote ${
            emotes[emotesShortCodes.indexOf(msg)]
          }`}
        ></span>
      );
    }
    return (
      <>
        <span
          id={`${data?.chat_id}-history`}
          key={`${data?.chat_id}-history`}
          className="preview previewIcon"
        >
          {msg}
        </span>
      </>
    );
  }

  function getMessages(value) {
    // console.log(value);
    setCurrentChatmateProfile();
    try {
      axiosAuth()
        .post(`${url}/chats/get-messages`, value)
        .then((response) => {
          // console.log(data?.data?.status)
          if (response?.data?.status === 200) {
            setChat(response?.data?.data);
            setCurrentUserToOject(response?.data?.data?.to_profile);
            //
            if (typeof response?.data === "object" && response.data != null) {
              setTimeout(() => {
                setCurrentUserTo(response?.data?.data?.to);
              }, 100);

              // console.log(response?.data?.data);
              setTimeout(() => {
                setLastmessageTimeStamp(response?.data?.data?.last_timestamp);
              }, 10);

              setTimeout(() => {}, 0);

              // currentChatmateProfile.pinned = response?.data.pinned;
              setIsChatPinned(response?.data?.data?.pinned);
              setMuteCall(response?.data?.data?.call_muted);

              if (!response?.data?.data?.video_call_available) {
                setVideoCallAvailable(false);
                document
                  .getElementById("videoCallBt")
                  .classList.add("unavailable");
              } else {
                setTimeout(() => {
                  setVideoCallAvailable(true);
                }, 5);

                document
                  .getElementById("videoCallBt")
                  .classList.remove("unavailable");
              }

              if (response?.data?.data?.blocked) {
                chatBox.classList.add("blocked");
              } else {
                // chatBox.classList.remove('blocked');
              }

              if (response?.data?.data?.call_muted) {
                document.getElementById("muteCallsBt").innerHTML =
                  "Unmute Calls";
              } else {
                // document.getElementById('muteCallsBt').innerHTML = 'Mute Calls';
              }

              // if(response?.data?.data?.all_messages_loaded){
              setAllMessages(response?.data?.data?.all_messages_loaded);
              // }

              if (response?.data?.data?.blocked_by_receiver) {
                chatBox.classList.add("blocked");
                chatBox.classList.add("receiver");
              } else {
                // chatBox.classList.remove('receiver');
              }
              // console.log(response?.data)
              if (
                typeof response?.data?.data?.messages === "object" &&
                response?.data?.data?.messages != null
              ) {
                var HTML = [];
                setChatMessageObject(response?.data?.data?.messages);
                for (const [dataKey, dataValue] of Object.entries(
                  response?.data?.data?.messages
                )) {
                  // FOREACH CHAT HISTORY
                  // console.log(response?.data?.data?.to_profile)
                  var html = generateMessageHTML(
                    dataValue,
                    response?.data?.data?.to_profile?.first_name
                  );
                  HTML.push(html);
                }
                //   console.log(HTML);
                setChatMessage(HTML);
                setTimeout(() => {
                  scrollToBottom();
                }, 10);
              }
            }
          }
        })
        .catch((error) => {
          if (error?.code === "ERR_NETWORK") {
            showAlert(error?.message);
          }
        });
    } catch (error) {}
  }

  function showOwnEmotePopup(e) {
    e.preventDefault();
    document.getElementById("mainmessage").classList.remove("hidden");
    setMainEmote(true);
  }

  function showEmotePopup(e, messageId) {
    e.preventDefault();
    document.getElementById("emotes-" + messageId).classList.remove("hidden");
    setCurrentMessageEmoPopup("emotes-" + messageId);
    // console.log("messageId");
  }

  function handleSelectEmote(data) {
    // console.log(data);
    if (data?.messageid === "mainmessage") {
      document.getElementById(data?.messageid).classList.add("hidden");
      sendEmoteAsMessage(data?.emote);
      setMainEmote(false);
    } else {
      document
        .getElementById("emotes-" + data?.messageid)
        .classList.add("hidden");
      sendEmote(data?.messageid, data?.emote);
    }

    //
  }

  function sendEmoteAsMessage(data) {
    var message = "![emote-" + data + "]!";

    var dataValue = {
      from: profileData?.main.id,
      to: currentUserTo,
      chat_request_id: currentChatIdRef.current,
      message: message,
      language: language,
    };

    try {
      axiosAuth()
        .post(`${url}/chats/send-message`, dataValue)
        .then((response) => {
          // if (document.getElementById("chat-" + currentChatId)) {
          //   document
          //     .getElementById("chat-" + currentChatId)
          //     .querySelector(
          //       ".preview"
          //     ).innerHTML = `<span class="preview emoteIcon emote ${data}"></span>`;
          // }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            showAlert(error?.response?.data?.message);
          } else if (error?.code === "ERR_NETWORK") {
            showAlert(error?.message);
          } else if (error?.response?.data?.status === 422) {
            showAlert(error?.response?.data?.data);
          } else {
            showAlert("We have encountered an error");
          }
          getuserChatHistory();
        });
    } catch (e) {
      getuserChatHistory();
      showAlert("We have encountered an error");
      // console.log(e);
    }
  }

  function generateMessageHTML(data, profile) {
    var own = data?.from_user_id === profileData?.main?.id;
    var msg = "";
    var userprofile = "";

    if (profile) {
      setCurrentChatmateProfile(profile);
      // console.log('hee')
      userprofile = profile;
    } else {
      userprofile = currentUserToRef?.current?.first_name;
    }

    var emotes = [
      "wow",
      "inlove",
      "heart",
      "smile",
      "lol",
      "sad",
      "wink",
      "laugh",
      "shock",
      "tongue",
    ];
    var emotesShortCodes = [];

    for (var x = 0; x < emotes.length; x++) {
      emotesShortCodes.push("![emote-" + emotes[x] + "]!");
    }
    // console.log(userprofile)

    // console.log(data?.to_user_id)
    // console.log(profileData?.main?.id)
    switch (data.chat_message) {
      case "![nudge]!":
        if (data?.to_user_id != profileData?.main?.id)
          msg = t("nudge") + " " + userprofile + "!";
        else msg = userprofile + " " + t("nudged") + "!";

        return (
          <div
            id={`chat-${data?.id}-${data?.date} `}
            key={`chat-${data?.id}-${data?.date} `}
            className={`message nudge ${own ? "own " : " "} chat-${data.id}`}
          >
            <span
              className="text"
              id={`chat-${data?.id}-span-child`}
              key={`chat-${data?.id}-span-child`}
            >
              {msg}
              <div
                key={`chat-${data?.id}-span-child`}
                className={`time ${
                  profileData?.is_premium ? " " : "read-status-hidden"
                }`}
              >
                {data?.date}
              </div>
            </span>
          </div>
        );

      case "![call-unanswered]!":
        msg = t("missed-call");

        return (
          <div
            id={`chat-${data?.id}-${data?.date} `}
            key={`chat-${data?.id}-${data?.date}`}
            className={`message call call-missed ${own ? "own " : " "} chat-${
              data?.id
            }`}
          >
            <span
              className="text"
              id={`chat-${data?.id}-${data?.date} `}
              key={`chat-${data?.id}-${data?.date}`}
            >
              {msg}
              <div
                key={`chat-${data?.id}-span-child`}
                className={`time ${
                  profileData?.is_premium ? " " : "read-status-hidden"
                }`}
              >
                {data?.date}
              </div>
            </span>
          </div>
        );

      case "![call-started]!":
        msg = t("call-has-started");

        return (
          <div
            id={`chat-${data?.id}-${data?.date} `}
            key={`chat-${data?.id}-${data?.date}`}
            className={`message call call-start ${own ? "own " : ""} chat-${
              data?.id
            }`}
          >
            <span
              className="text"
              id={`chat-${data?.id}-${data?.date} `}
              key={`chat-${data?.id}-${data?.date}`}
            >
              {msg}
              <div
                key={`chat-${data?.id}-span-child`}
                className={`time ${
                  profileData?.is_premium ? " " : "read-status-hidden"
                }`}
              >
                {data?.date}
              </div>
            </span>
          </div>
        );

      case "![call-ended]!":
        msg = t("call-has-ended");

        return (
          <div
            id={`chat-${data?.id}-${data?.date} `}
            key={`chat-${data?.id}-${data?.date}`}
            className={`message call call-ended ${own ? "own " : ""}' chat-${
              data?.id
            }`}
          >
            <span
              className="text"
              id={`chat-${data?.id}-${data?.date} `}
              key={`chat-${data?.id}-${data?.date}`}
            >
              {msg}
              <div
                key={`chat-${data?.id}-span-child`}
                className={`time ${
                  profileData?.is_premium ? " " : "read-status-hidden"
                }`}
              >
                {data?.date}
              </div>
            </span>
          </div>
        );

      case "![call-declined]!":
        msg = t("call-declined");

        return (
          <div
            id={`chat-${data?.id}-${data?.date} `}
            key={`chat-${data?.id}-${data?.date}`}
            className={`message call call-declined '${own ? "own" : " "} chat-${
              data?.id
            }`}
          >
            <span
              className="text"
              key={`chat-${data?.id}-${data?.date}`}
              id={`chat-${data?.id}-${data?.date} `}
            >
              {msg}
              <div
                key={`chat-${data?.id}-span-child`}
                className={`time ${
                  profileData?.is_premium ? " " : "read-status-hidden"
                }`}
              >
                {data?.date}
              </div>
            </span>
          </div>
        );
    }

    // console.log(emotesShortCodes)

    if (emotesShortCodes.includes(data?.chat_message)) {
      return (
        <div
          id={`chat-${data?.id}-${data?.date} `}
          key={`chat-${data?.id}-${data?.date}`}
          className={`message emoteOnly ${own ? "own" : " "} chat-${data?.id}`}
        >
          <span
            className="text"
            key={`chat-${data?.id}-${data?.date}`}
            id={`chat-${data?.id}-${data?.date} `}
          >
            <span
              key={`chat-${data?.id}-${data?.date}`}
              id={`chat-${data?.id}-${data?.date}`}
              className={`emoteIcon emote ${
                emotes[emotesShortCodes.indexOf(data?.chat_message)]
              }
              `}
            ></span>
            <div
              key={`chat-${data?.id}-span-child`}
              className={`time ${
                profileData?.is_premium ? " " : "read-status-hidden"
              }`}
            >
              {data?.date}
            </div>
          </span>
        </div>
      );
    }

    return (
      <>
        <div
          id={`chat-${data?.id}-${data?.date}`}
          key={`chat-${data?.id}-${data?.date}`}
          className={`message ${own ? "own" : " "} chat-${data.id}`}
        >
          <span
            className="text"
            key={`chat-${data?.id}-${data?.date}`}
            id={`chat-${data?.id}-${data?.date} `}
          >
            {data?.chat_message}
            <div
              key={`chat-${data?.id}-span-child`}
              className={`time ${
                profileData?.is_premium ? " " : "read-status-hidden"
              }`}
            >
              {data.date}{" "}
              {!own ? (
                <>
                  <button
                    className={`emote ${data.emote ? data.emote.type : " "}`}
                    onClick={(e) => showEmotePopup(e, data.id)}
                  ></button>
                </>
              ) : data.emote ? (
                <button className={`emote ${data.emote.type}`}></button>
              ) : (
                ""
              )}
              <div id={`emotes-` + data?.id} className="hidden user-emo-popup">
                <Emotes onSelect={handleSelectEmote} messageId={data.id} />
              </div>
            </div>
          </span>
        </div>
      </>
    );
  }

  //
  function handleKeyPress(e) {
    e.preventDefault();
    if (e.key === "Enter") {
      sendMessage(e);
    }
  }

  const userHandler = (e, data) => {
    e.preventDefault();
    navigate("/user/" + data);
    // console.log(data);
  };

  useEffect(() => {
    // Event listener to handle clicks on the document
    function handleClickOutside(event) {
      //  const mainMessage = document.getElementById('mainmessage');
      //  console.log(mainMessage)
      //  console.log(mainEmote)
      //  if (mainMessage.classList.contains("hidden")) {
      //   console.log('hidden')
      // } else if(!mainMessage.classList.contains('hidden')) {
      //   console.log('hiefifi')
      //   setMainEmote(false)
      //   //  mainMessage.classList.add('hidden'); // Hide if not hidden
      // }
    }

    // Add the event listener when the component mounts
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [mainEmote]); // Empty dependency array ensures the effect runs once when the component mounts

  ///////////////////////////////////

  // Video functions

  useEffect(() => {
    // const messagesBox = document.getElementById('messages-box');
    const messagesBox = messagesBoxRef.current;

    // console.log(lastMessageTimestamp)
    // console.log(currentChatId)

    const handleScroll = () => {
      if (messagesBox) {
        var scrollTop = messagesBox.scrollTop;
        if (
          scrollTop + messagesBox.scrollHeight - 1 <=
          Math.ceil(messagesBox.clientHeight)
        ) {
          loadMoreMessages();
          // console.log(scrollTop + messagesBox.scrollHeight)
        } else if (scrollTop === 0) {
        } else {
        }
      }
    };
    if (messagesBox) {
      messagesBox.removeEventListener("scroll", handleScroll);
      // Add an event listener for the scroll event on the 'messages-box' element
      messagesBox.addEventListener("scroll", handleScroll);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      if (messagesBox) {
        messagesBox.removeEventListener("scroll", handleScroll);
      }
      // messagesBox = document.getElementById('messages-box');
    };
  }, [currentChatId, lastMessageTimestamp]);

  const scrollToBottom = () => {
    if (messagesBoxRef.current) {
      messagesBoxRef.current.scrollTop = 0;
    }
  };

  function loadMoreMessages() {
    // console.log(currentChatId)
    // console.log(lastMessageTimestamp)
    if (lastMessageTimestamp && currentChatId) {
      // console.log('herereer ')
      // const messagesBox = simpleChatBarRef.current.getScrollElement();
      var data = {
        chat_id: currentChatId,
        user_id: profileData?.main?.id,
        limit: 10,
        last_timestamp: lastMessageTimestamp,
        language: language,
      };

      //  console.log(allmessagesloaded)

      if (!allmessagesloaded) {
        try {
          axiosAuth()
            .post(`${url}/chats/get-messages`, data)
            .then((response) => {
              // console.log()

              // if(response?.data?.data?.all_messages_loaded){
              setAllMessages(response?.data?.data?.all_messages_loaded);
              // }

              // setLastmessageTimeStamp(null)
              if (
                typeof response?.data?.data?.messages === "object" &&
                response?.data?.data?.messages != null
              ) {
                setTimeout(() => {
                  setLastmessageTimeStamp(response?.data?.data?.last_timestamp);
                }, 50);
                var HTML = [];
                for (const [dataKey, dataValue] of Object.entries(
                  response?.data?.data?.messages
                )) {
                  // FOREACH CHAT HISTORY
                  // console.log(response?.data?.data?.to_profile)
                  var html = generateMessageHTML(
                    dataValue,
                    response?.data?.data?.to_profile?.first_name
                  );
                  setChatMessageObject((msgs) => [...msgs, dataValue]);
                  HTML.push(html);
                }
                setChatMessage((msgs) => [...msgs, HTML]);
                //
              }
            })
            .catch((error) => {
              if (error?.code === "ERR_NETWORK") {
                showAlert(error?.message);
              }
            });
        } catch {}
      }
    }
  }

  function handleVideoCall(e) {
    e.preventDefault();

    var htmlEls = {
      localIdentity: document.getElementById("localIdentity"),
      localParticipant: document.getElementById("caller-video"),
      remoteParticipant: document.getElementById("participant-video"),
    };
    // console.log(document.getElementById("localIdentity"))
    sendVideoCallRequest(
      videoCallAvailable,
      profileData,
      currentUserTo,
      currentChatId,
      htmlEls
    );
  }

  function handleAcceptVideoCall(e) {
    e.preventDefault();
    var htmlEls = {
      localParticipant: document.getElementById("caller-video"),
      remoteParticipant: document.getElementById("participant-video"),
    };
    acceptVideoCall(videochatdata, profileData, currentChatId, htmlEls);
  }

  const handleAcceptVideoCallFromExternal = (callId, cllrId) => {
    setVideCallActive(false);

    var htmlEls = {
      localParticipant: document.getElementById("caller-video"),
      remoteParticipant: document.getElementById("participant-video"),
    };
    acceptCallFromExternal(callId, profileData, cllrId, htmlEls);
    // acceptVideoCall();
  };

  // scroll function

  return (
    <>
      {!profileData?.is_premium && (
        <>
          <div id="floatingAd" className={`${showadd ? "" : "hidden"}`}>
            <button className="button"></button>
            <div className="adcontent">
              <div className="lft">
                <img src={AdOne} alt="video call icon" />
              </div>
              <div className="rght">
                <button className="close" onClick={(e) => setShowAdd(false)}>
                  <img src={closeBtn} alt="close-button" />
                </button>
                <p>Introducing</p>
                <h3>Video Calling</h3>
                <a href="/premium">Learn more</a>
              </div>
            </div>
          </div>
        </>
      )}

      <div id="messagePage" className="messagePage">
        <div id="messages-page">
          <div
            id="likes-popup"
            className={`popup-main ${recentlylikedpopup ? " active" : " "}`}
            onClick={(e) => closePopups(e)}
          >
            <div className="wrap">
              <button
                className="close"
                onClick={(e) => closePopups(e)}
              ></button>
              <span className="heading">
                {t(["recently-liked-profiles", ""])}
              </span>

              <div className="user-feeds" ref={LikesRef} id="likedProfiles">
                {Object.entries(recentlyLikedMore).map(([key, value]) => (
                  // console.log(value?.is_premium),
                  <div key={key} className="user">
                    <div
                      className={`user-photo ${
                        value.is_premium ? "premium" : ""
                      }`}
                    >
                      {value.name && (
                        <a
                          href={`/user/${value.slug}/`}
                          onClick={(e) => userHandler(e, value.slug)}
                        >
                          {value?.photo ? (
                            <>
                              <img
                                src={value?.photo}
                                alt={`User ${value.slug} ${key}`}
                                onError={handleImageError}
                              />
                            </>
                          ) : (
                            <img
                              src={placeholderImage}
                              alt={`User ${value.slug} ${key}`}
                              onError={handleImageError}
                            />
                          )}
                        </a>
                      )}
                    </div>
                    {value?.name && typeof value?.name !== "undefined" && (
                      <span>{capitalizeWords(value?.name)}</span>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div
            id="call-popup"
            className="popup-main call has-icon"
            onClick={(e) => closePopups(e)}
          >
            <div className="wrap">
              <div id="incomingCall">
                {/* <ShowIncomingCall  data={videochatdata}/> */}

                {/*  */}

                <span className="heading">
                  <span id="callerName">{videochatdata?.data?.first_name}</span>{" "}
                  {t(["video-call-notification", ""])}...
                </span>
                <div className="buttons mt-20">
                  <button
                    className="accept"
                    onClick={(e) => handleAcceptVideoCall(e)}
                  >
                    <span>{t(["accpet-button", ""])}</span>
                  </button>
                  <button
                    className="reject"
                    onClick={(e) =>
                      rejectVideoCall(
                        e,
                        videochatdata,
                        profileData,
                        currentChatId
                      )
                    }
                  >
                    <span>{t(["reject-button", ""])}</span>
                  </button>
                </div>

                {/*  */}
              </div>
            </div>
          </div>

          <div
            id="report-popup"
            className={`popup-main report has-icon ${
              reportuserpopup ? "active" : ""
            } `}
          >
            <div className="wrap">
              <button
                className="close"
                onClick={(e) => closePopups(e)}
              ></button>

              <div id="reportForm">
                <span className="heading">{t("report-popup")}</span>
                <div className="field">
                  <div className="details">{t(["report-option-1", ""])}</div>
                  <button
                    className="submit-report-bt"
                    onClick={(e) => reportUser(e, "Inappropriate language")}
                  ></button>
                </div>
                <div className="field">
                  <div className="details">{t(["report-option-2", ""])}</div>
                  <button
                    className="submit-report-bt"
                    onClick={(e) => reportUser(e, "Abusive behaviour")}
                  ></button>
                </div>
                <div className="field">
                  <div className="details">{t(["report-option-3", ""])}</div>
                  <button
                    className="submit-report-bt"
                    onClick={(e) => reportUser(e, "Fake profile")}
                  ></button>
                </div>
                <div className="field">
                  <div className="details">{t(["report-option-4", ""])}</div>
                  <textarea
                    id="other-report"
                    placeholder={t(["write-something-here-placeholder", ""])}
                    onChange={(e) => setReportReason(e.target.value)}
                  ></textarea>
                  <button
                    className="submit-report-bt"
                    onClick={(e) => reportUser(e, reportReason)}
                  ></button>
                </div>
                <span className="subheading mt-30">{t("report-notice")}</span>
              </div>

              <div id="reportSuccess">
                <span className="heading">
                  {t(["report-success-popup", ""])}
                </span>
                <span className="subheading mt-30">
                  {t("report-success-text")}
                  <br />
                  <br />
                  {t("report-success-text-2")}
                </span>
              </div>
            </div>
          </div>

          <div
            id="block-popup"
            className={`popup-main block has-icon ${
              blockuserpopup ? "active" : ""
            } `}
          >
            <div className="wrap">
              <button
                className="close"
                onClick={(e) => closePopups(e)}
              ></button>
              <span className="heading">
                {t(["block-popup", ""])} <span id="blockUserName"></span>?
              </span>
              <ul className="icon-list">
                <li className="no-text">{t(["block-text-1", ""])}</li>
                <li className="no-notification">{t(["block-text-2", ""])}</li>
              </ul>

              <div className="text-center">
                <button className="main-bt mt-20" onClick={(e) => blockChat(e)}>
                  {t(["block", ""])}
                </button>
              </div>
            </div>
          </div>

          <div id="busy-popup" className="popup-main block has-icon">
            <div className="wrap">
              <button
                className="close"
                onClick={(e) => closePopups(e)}
              ></button>
              <span className="heading">{t("user-busy-heading")}</span>
              <span className="subheading mt-30">
                {t(["user-busy-message", ""])}
              </span>
            </div>
          </div>

          <div id="server-busy-popup" className="popup-main block has-icon">
            <div className="wrap">
              <button
                className="close"
                onClick={(e) => closePopups(e)}
              ></button>
              <span className="heading">{t("server-busy-header")}</span>
              <span className="subheading mt-30">
                {t(["server-busy-text", ""])}
              </span>
            </div>
          </div>

          <div id="report-success" className="popup-main check has-icon">
            <div className="wrap">
              <button
                className="close"
                onClick={(e) => closePopups(e)}
              ></button>
              <span className="heading">{t(["report-success-popup", ""])}</span>
              <span className="subheading mt-30">
                {t(["report-success-text", ""])}
                <br />
                <br />
                {t(["report-success-text-2", ""])}
              </span>
            </div>
          </div>

          <div id="non-premium-popup" className="popup-main block has-icon">
            <div className="wrap">
              <button
                className="close"
                onClick={(e) => closePopups(e)}
              ></button>
              <span className="heading">{t(["premium-popup", ""])}</span>
              <span className="subheading mt-30">
                {t(["premium-popup-text", ""])}
              </span>
            </div>
          </div>

          <div
            className={`left column ${isSmallScreen && isChat ? "hide" : ""}`}
          >
            <div
              id="likedUserCarousel"
              className={` ${imagesLoaded ? "" : "hidden"}`}
            >
              <div className="heading">
                <div className="wrap d-flex justify-content-between">
                  <div>{t(["you-recently-liked", ""])}</div>
                  <div>
                    <a href="/" onClick={(e) => showLikeUsersPopup(e)}>
                      {t(["see-all", ""])}
                    </a>
                  </div>
                </div>
              </div>
              <div className={`recent-users d-flex `}>
                {/* {console.log(recently)} */}
                {recently?.length > 0 ? (
                  <RecentlyLiked
                    data={recently}
                    onImageLoad={handleImageLoad}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>

            {/* Ad */}

            {!profileData?.is_premium && (
              <>
                <div id="chatTopAdSmaller" className="">
                  <div className="lft">
                    <div className="wrap">
                      <p>Introducing</p>
                      <h3>Video Calling</h3>
                      <a href="/premium">Learn More</a>
                    </div>
  
                  </div>
                  <div className="image">
                      <img src={videoCall} alt="Video Call" />
                    </div>
                </div>

                <div id="chatTopAd" className="">
                  <div className="lft" style={{}}></div>
                  <div className="rght">
                    <div className="wrap">
                      <p>Introducing</p>
                      <h3>Video Calling</h3>
                      <a href="/premium">Learn More</a>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="heading no-border">
              <div className="top">{t(["chats", ""])}</div>
            </div>
            <div className="feeds" id="chatSidebar">
              {chatpinned ? (
                //  console.log(chathistory),
                <>
                  {Object.entries(currentpinned?.current).map(
                    ([key, value]) => (
                      // console.log(value),
                      <>
                        {/* {console.log(value?.latest_message?.from_user_id)}
                    {console.log(profileData?.main?.id)}      
                    {console.log(value)}  */}

                        <li
                          id={`chat-${value.chat_id}`}
                          key={`chat-${value.chat_id}`}
                          className={`feed custom-link ${
                            value?.slug === "support-mhb-2023"
                              ? "is_support"
                              : ""
                          }  pinned ${value?.blocked ? "blocked" : ""}  ${
                            currentChatIdRef.current === value?.chat_id
                              ? " activeUser read"
                              : ""
                          }  
                        
                        ${
                          value?.unread &&
                          value?.latest_message?.from_user_id !==
                            profileData?.main?.id
                            ? "  "
                            : " read"
                        }`}
                          onClick={(e) =>
                            selectUserChat(
                              e,
                              value.chat_id,
                              value,
                              currentpinned?.current,
                              "pinned"
                            )
                          }
                        >
                          <div className="user">
                            <div
                              className={`user-photo ${
                                value?.is_premium ? " premium" : ""
                              } ${
                                value?.is_online ||
                                value?.slug === "support-mhb-2023"
                                  ? "  active"
                                  : " "
                              }
                                `}
                            >
                              {value?.photo ? (
                                <>
                                  <img
                                    src={value?.photo}
                                    alt={value?.slug}
                                    onError={handleImageError}
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    src={placeholderImage}
                                    alt={value?.slug}
                                    onError={handleImageError}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          <div className="details">
                            <div>
                              <span className="name">
                                {value?.first_name &&
                                  typeof value?.first_name !== "undefined" && (
                                    <span>
                                      {capitalizeWords(value?.first_name)}
                                    </span>
                                  )}
                              </span>
                            </div>
                            <div className="content">
                              {/* <span className="preview" dangerouslySetInnerHTML={{ __html:Msg(value)}}></span> */}
                              {Msg(value.chat_id, value)}
                              <div>
                                <span className="timestamp">
                                  {value?.last_updated}
                                </span>

                                <span
                                  className={`read-status ${
                                    profileData?.main?.id !==
                                      value?.latest_message?.from_user_id &&
                                    value?.unread
                                      ? ""
                                      : "read"
                                  }  ${
                                    profileData?.is_premium
                                      ? " premium"
                                      : "hide-chat-ticks"
                                  }`}
                                ></span>
                              </div>
                            </div>
                          </div>
                        </li>
                      </>
                    )
                  )}
                </>
              ) : (
                <></>
              )}

              {chatunpinned ? (
                //  console.log(chathistory),
                <>
                  {Object.entries(currentUnpinned?.current).map(
                    ([key, value]) => (
                      // console.log(value),
                      <>
                        {/* {value?.unread} +
                    {value?.chat_id} +
                    {currentChatId} + */}
                        {/* <li id="chat-'+dataValue.chat_id+'" class="feed new '+ ((dataValue.unread && dataValue.chat_id != currentChatId) ? '' : 'read' ) +' '+ (isPinned ? 'pinned' : '' ) +' '+ (dataValue.blocked ? 'blocked' : '' ) +'" onclick=getChatMessages("'+dataValue.chat_id+'")> */}

                        {/* ${value?.unread === value?.chat_id !== currentChatId ? '' : 'read'} */}

                        {/* ${
                          profileData?.main?.id !==
                            value?.latest_message?.from_user_id &&
                          value?.unread &&
                          currentChatId !== value?.chat_id
                            ? ""
                            : "read"
                        } */}

                        {/* {profileData?.main?.id} */}

                        {}

                        <li
                          key={`chat-${value.chat_id}`}
                          id={`chat-${value.chat_id}`}
                          className={`custom-link feed new ${
                            value?.slug === "support-mhb-2023"
                              ? "is_support"
                              : ""
                          } ${value?.isPinned ? " pinned " : ""} ${
                            value?.blocked ? "blocked" : ""
                          } ${
                            currentChatIdRef.current === value?.chat_id
                              ? " activeUser read "
                              : ""
                          } ${
                            value?.unread &&
                            value?.latest_message?.from_user_id !==
                              profileData?.main?.id
                              ? "  "
                              : " read"
                          }  `}
                          onClick={(e) =>
                            selectUserChat(
                              e,
                              value.chat_id,
                              value,
                              currentUnpinned?.current,
                              "unpinned"
                            )
                          }
                        >
                          <div className="user">
                            <div
                              className={`user-photo ${
                                value?.is_premium ? " premium" : ""
                              } ${
                                value?.is_online ||
                                value?.slug === "support-mhb-2023"
                                  ? "  active"
                                  : " "
                              }`}
                            >
                              <img
                                src={value?.photo}
                                alt={value?.slug}
                                onError={handleImageError}
                              />
                            </div>
                          </div>
                          <div className="details">
                            <div>
                              {value?.first_name &&
                                typeof value?.first_name !== "undefined" && (
                                  <span>
                                    {capitalizeWords(value?.first_name)}
                                  </span>
                                )}
                            </div>
                            <div className="content">
                              {/* <span className="preview">{Msg(value)}</span>
                               */}
                              {/* {Msg(value)} */}
                              {Msg(value.chat_id, value)}
                              <div className="read-time">
                                <span className="timestamp">
                                  {value?.last_updated}
                                </span>
                                {/* {console.log(value)} */}
                                <span
                                  className={`read-status ${
                                    profileData?.is_premium
                                      ? " premium"
                                      : "hide-chat-ticks"
                                  }`}
                                ></span>
                              </div>
                            </div>
                          </div>
                        </li>
                      </>
                    )
                  )}
                </>
              ) : (
                <></>
              )}
              {currentUnpinned?.current || currentpinned?.current ? (
                <></>
              ) : (
                <>
                  <li className="feed new read">
                    {t(["no-chat-message", ""])}
                  </li>
                </>
              )}
            </div>
          </div>

          <div
            className={`mid column ${isSmallScreen && isChat ? "active" : ""}`}
            id="midColumn"
          >
            {currentUnpinned?.current || currentpinned?.current ? (
              <>
                <div
                  className={`chat-wrap ${
                    chat?.to_profile?.slug === "support-mhb-2023"
                      ? "is_support"
                      : ""
                  }`}
                >
                  <div className="dash ">
                    <div className="left" id="">
                      <div className="user">
                        {/* {console.log(chat)} */}
                        <div
                          className={`user-photo ${
                            chat?.to_profile?.is_premium ? "premium" : " "
                          }
                              ${
                                chat?.to_profile?.is_online ||
                                chat?.to_profile?.slug === "support-mhb-2023"
                                  ? "  active"
                                  : " "
                              }
                              `}
                        >
                          {chat?.to_profile ? (
                            <>
                            <a href={`/user/${chat?.to_profile?.slug}/`} >
                              <img
                                id="currentChatUserImage"
                                src={chat?.to_profile?.photo}
                                alt="dsdsdsds"
                                onError={handleImageError}
                              />
                              </a>
                            </>
                          ) : (
                            <>
                            <a href={`/user/${chat?.to_profile?.slug}/`} >
                              <img
                                id="currentChatUserImage"
                                src={placeholderImage}
                                alt="Mohabbetein"
                                onError={handleImageError}
                              />
                              </a>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="details">
                        <div>
                          <span className="name" id="currentChatUserName">
                            {chat?.to_profile ? (
                              <>
                                {chat?.to_profile?.first_name}{" "}
                                {chat?.to_profile?.last_name}
                              </>
                            ) : (
                              <></>
                            )}
                          </span>
                        </div>
                        <div className="content">
                          <span
                            className={`preview ${
                              chat?.to_profile?.is_online ||
                              chat?.to_profile?.slug === "support-mhb-2023"
                                ? "active"
                                : ""
                            }`}
                          >
                            {chat?.to_profile?.slug === "support-mhb-2023" ? (
                              <>
                                <span>We're here to help you</span>
                              </>
                            ) : (
                              <>
                                {chat?.to_profile?.is_online ? (
                                  <> {t(["active", ""])}</>
                                ) : (
                                  <>{t(["offline-label", ""])}</>
                                )}
                              </>
                            )}
                          </span>

                          <span
                            className={`read-status ${
                              profileData?.is_premium
                                ? " premium"
                                : "hide-chat-ticks"
                            }`}
                          ></span>
                        </div>
                      </div>
                    </div>
                    <div className="right">
                      <a href="#" id="chatBack" onClick={chatBack}></a>

                      {/* <div id="videocallTimer">
                        <strong id="timeleft">00:00</strong>
                      </div> */}
                      <ul className="actions">
                        <Tooltip
                          id="nudge-tooltip"
                          className="main-tooltips"
                          classNameArrow="custom-arrows"
                        />
                        <Tooltip
                          id="call-tooltip"
                          className="main-tooltips"
                          classNameArrow="custom-arrows"
                        />
                        <li>
                          <a
                            href="/"
                            className="nudge action"
                            onClick={(e) =>
                              sendNudge(e, chat?.to_profile?.first_name)
                            }
                            data-tooltip-id="nudge-tooltip"
                            data-tooltip-content="Nudge"
                          ></a>
                        </li>
                        <li>
                          <a
                            href="/"
                            onClick={(e) => handleVideoCall(e)}
                            className="videocall action"
                            id="videoCallBt"
                            data-tooltip-id="call-tooltip"
                            data-tooltip-content="Call"
                          ></a>
                        </li>
                        <li className={showchatoption ? "active" : ""}>
                          <a
                            href="#"
                            className="others action"
                            onClick={(e) => chatOptions(e)}
                          ></a>
                          <div id="chat-menu" className="clearfix">
                            <ul>
                              <li>
                                <a
                                  href="/"
                                  className="pin"
                                  onClick={(e) => pinChat(e)}
                                  id="pinChatBt"
                                >
                                  {ischatpinned ? t("unpin") : t("pin")}
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/"
                                  className="block"
                                  id="blockChatBt"
                                  onClick={(e) => blockUser(e)}
                                >
                                  {t(["block", ""])}
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  className="report"
                                  onClick={(e) => showReportUser(e)}
                                >
                                  {t(["report", ""])}
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  className="mute"
                                  onClick={(e) => muteCall(e)}
                                  id="muteCallsBt"
                                >
                                  {mutecall ? (
                                    <>{t(["unmute", ""])}</>
                                  ) : (
                                    <>{t(["mute", ""])}</>
                                  )}
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  className="delete"
                                  onClick={(e) => deleteChat(e)}
                                >
                                  {t(["delete", ""])}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div id="videocallbox">
                    <div id="participant-video"></div>
                    <div id="caller-video"></div>
                    <div className="buttons">
                      <button
                        className="action drop"
                        onClick={(e) => endVideoCall(e, currentChatId)}
                      ></button>
                    </div>
                  </div>

                  {/* {isSmallScreen? (<></>):(<></>)} */}
                  {/* style={{height:chatBoxHeight}} */}
                  <div id="chatbox">
                    {/* {{-- <span className="scrolldate">Today</span> --}} */}
                    {/* <SimpleBar */}
                    <div
                      id="messages-box"
                      className="simple-barsssss "
                      ref={messagesBoxRef}
                    >
                      {ischathistoryLoading && !chatmessage ? (
                        <>
                          {" "}
                          <div className="loader"></div>
                        </>
                      ) : (
                        <></>
                      )}
                      {chatmessage}
                    </div>
                    {/* </SimpleBar> */}
                    <div className="input-wrap">
                      <div className="user-photo">
                        <img
                          src={profileData?.photo}
                          id="currentAuthUser"
                          alt=""
                          onError={handleImageError}
                        />
                      </div>
                      <div className="form">
                        <div className="forms">
                          <input
                            type="text"
                            id="message-field"
                            // placeholder={t(['write-something-placeholder',''])}
                            placeholder={`Write something...`}
                            value={valuemessage}
                            onChange={(e) => setValueMessage(e.target.value)}
                            onKeyUp={(e) => handleKeyPress(e)}
                            ref={inputRef}
                          />
                          <div id="emotes">
                            <button
                              onClick={(e) => showOwnEmotePopup(e)}
                            ></button>
                            <div
                              id={`mainmessage`}
                              className="hidden"
                              ref={emoRef}
                            >
                              <Emotes
                                onSelect={handleSelectEmote}
                                messageId={"mainmessage"}
                              />
                            </div>
                          </div>

                          {valuemessage === "" ? (
                            <button
                              id="submit-bt"
                              onClick={(e) => sendMessage(e)}
                              disabled
                            >
                              {t(["send", ""])}
                            </button>
                          ) : (
                            <button
                              id="submit-bt"
                              onClick={(e) => sendMessage(e)}
                            >
                              {t(["send", ""])}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>

          <div
            className={`right column ${
              chat?.to_profile?.slug === "support-mhb-2023" ? "is_support" : ""
            }`}
            id="rightColumn"
          >
            {currentUnpinned?.current || currentpinned?.current ? (
              <>
                <UserDetails data={chat} />
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}